import { useEffect, useState } from "react";
import {
  getNativeBalance,
  getTokenBalance,
} from "../services/contractServices/contractCallServices";
import { NETWORKS } from "../utils/constants";
import { NetworkTypes, TOKEN_DETAILS } from "../interfaces/common";
import { useSelector } from "react-redux";

const useFetchTokenBalance = ({
  dispatch,
  tokenDetails,
}: {
  dispatch: any;
  tokenDetails: TOKEN_DETAILS;
}) => {
  const [tokenBalance, setTokenBalance] = useState({
    token1Balance: 0,
    token1BalanceConverted: 0,
    token2Balance: 0,
    token2BalanceConverted: 0,
  });
  const walletAddress = useSelector((state: any) => state?.user?.walletAddress);
  const selectedChain: NetworkTypes = useSelector(
    (state: any) => state?.user?.chainValues
  );

  const fetchData = async () => {
    if (walletAddress) {
      const { isTokenOneNative, isTokenTwoNative, tokenOneData, tokenTwoData } =
        tokenDetails;
      let res1: any;
      let res2: any;
      if (isTokenOneNative) {
        res1 = await getNativeBalance(walletAddress, window?.ethereum);
      } else {
        res1 = await getTokenBalance({
          tokenData: tokenOneData,
          dispatch,
          walletAddress: walletAddress,
          walletProvider: window?.ethereum,
        });
      }
      if (isTokenTwoNative) {
        res2 = await getNativeBalance(walletAddress, window?.ethereum);
      } else {
        console.log(tokenTwoData);
        res2 = await getTokenBalance({
          tokenData: tokenTwoData,
          dispatch,
          walletAddress: walletAddress,
          walletProvider: window?.ethereum,
        });
      }
      console.log({ res1, res2 });
      if (res1 && res2) {
        setTokenBalance({
          token1Balance: res1?.calculatedBalance || "0",
          token2Balance: res2?.calculatedBalance || "0",
          token1BalanceConverted: res1?.res,
          token2BalanceConverted: res2?.res,
        });
      }
    }
  };

  useEffect(() => {
    let inter: any;
    inter = setTimeout(() => {
      if (walletAddress && tokenDetails && selectedChain.chainId && window?.ethereum) {
        const findNetwork = NETWORKS.find(
          (network) => network.chainId === selectedChain.chainId
        );
        if (findNetwork || !findNetwork) {
          fetchData();
        }
      }
    }, 1000);

    if (!walletAddress) {
      setTokenBalance({
        token1Balance: 0,
        token1BalanceConverted: 0,
        token2Balance: 0,
        token2BalanceConverted: 0,
      });
    }
    return () => clearTimeout(inter);
  }, [walletAddress, tokenDetails, selectedChain.chainId, window?.ethereum]);

  return { tokenBalance, fetchData };
};

export default useFetchTokenBalance;
