import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DropUpswapIcon } from "../../../assets/icons/svgicons";
import { INPUTS } from "../../../interfaces/common";
import Button from "../../common/Button/Button";
import CommonTable from "../../common/CommonTable/CommonTable";
import ActiveTokenCard from "../../common/TokenCard/ActiveTokenCard";
import SecondaryTokenCard from "../../common/TokenCard/SecondaryTokenCard";
import "./ChainCross.scss";
// import { debounce } from "lodash";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { debounce } from "debounce.lodash";
import { storeReduxInstance } from "../../../App";
import {
  saveWalletProvider,
  setOkx_quote_data,
  setSaveOkxChainName,
  setSaveSelectedFrom,
  setSaveSelectedTo,
} from "../../../features/theme/Okx.slice";
import {
  ApprovalSendTransactionFunction,
  getAllowanceFunction,
  getQuotes,
  okxSwapApi,
  swapSendTransactionFunction,
} from "../../../services/OkxService/OkxService";
import {
  getNativeBalance,
  getTokenBalance,
} from "../../../services/contractServices/contractCallServices";
import {
  ApprovalAddress,
  fixAndConvertFunction,
  localeStringFunction,
  nativeData,
  networkChainName,
  switchNetwork,
  toFunctionBigNumber,
} from "../../../utils/OkxHelpers";
import {
  APIURL,
  EVENTS,
  feePercentOkx,
  referrerAddressOwnerOkx,
} from "../../../utils/constants";
import TxnConfirmation from "../../common/Modals/TxnConfirmation/TxnConfirmation";
import { toast } from "../../common/Toasts/Toast";
import SettingOverlayOKX from "../../common/SettingOverlayOKX/SettingOverlayOKX";
import { OkxContractServices } from "../../../services/contractServices/OkxContractServices";

const ChainCross = () => {
  const [show, setShow] = useState(false);
  const [inputOne, setinputOne] = useState<INPUTS>({
    inputValue: "",
    convertedValue: "",
    toolTipValue: "",
  });
  const [inputTwo, setinputTwo] = useState<INPUTS>({
    inputValue: "",
    convertedValue: "",
    toolTipValue: "",
  });

  const [shimmerState, setShimmerState] = useState<string>("");
  const [tk1DollarValue, setTk1DollarValue] = useState<number | string>(0.0);
  const [tk2DollarValue, setTk2DollarValue] = useState<number | string>(0.0);

  const tableheading = [
    { label: "Exchange" },
    { label: "You get" },
    { label: "Fees" },
  ];

  const tableData = [
    {
      exchange: "234.00",
      getvalue: "6,43,436,00",
      fees: "7.5%",
    },
    {
      exchange: "234.00",
      getvalue: "6,43,436,00",
      fees: "7.5%",
    },
    {
      exchange: "234.00",
      getvalue: "6,43,436,00",
      fees: "7.5%",
    },
  ];
  const tokenBalance: any = {};

  //---------------
  const { selectedTo, allTokenList, selectedFrom, okxQuoteData } =
    useAppSelector<any>((state) => state.okx);
  const CURRENT_NETWORK = localStorage.getItem("CURRENT NETWORK");

  //--------------------------------OKX Integration----------------------------------------------------
  const [tokenTwoIconnew, setTokenTwoIconnew] = useState(false);
  const [okxTokenTwoAddress, setOkxTokenTwoAddress] = useState();
  const [tokenOneChainId, setTokenOneChainId] = useState<any>("");
  const [tokenOneValue, setTokenOneValue] = useState<any>("");
  const { importedLp, walletAddress } = useAppSelector(
    (state: any) => state?.user
  );
  const [dataTx, setDataTx] = useState();
  const [tokenTwoDecimal, setTwoDecinmal] = useState(18);

  const [isNetworkSelected, setNetworkSelected] = useState(
    CURRENT_NETWORK !== null ? true : false
  );

  const dispatch = useAppDispatch();

  const deadline = useAppSelector((state) => state?.user?.deadline);
  const slippagePercentage = storeReduxInstance?.getState()?.okx?.slippage;
  const [tokenTwoValue, setTokenTwoValue] = useState<any>("");
  const [tokenOneBalance, setTokenOneBalance] = useState<any>(0);
  const [tokenTwoBalance, setTokenTwoBalance] = useState<any>(0);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [txHash, setTxHash] = useState("");
  const [transactionData, setTransactionData] = useState({
    status: false,
    txnHash: "",
    code: false,
  });
  const [amountIn, setAmountIn] = useState("");

  const { walletProvider } = useWeb3ModalProvider();

  const delayedQuery = useCallback(
    debounce((value: any) => {
      getQuote(value);
    }, 1000), // (*)
    []
  );

  const okxHandleChange = (value: any) => {
    if (value == "") {
      setTokenOneValue("");
      dispatch(setOkx_quote_data(""));
      setTokenTwoValue("");
    } else {
      setTokenOneValue(value);
      if (value != 0) {
        delayedQuery(value);
      }
    }
  };

  const getQuote = async (tokenOneValue: any) => {
    try {
      if (tokenOneValue == "") {
        return;
      }
      dispatch(setOkx_quote_data(""));

      const endPoint =
        storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID ==
        storeReduxInstance?.getState()?.okx?.selectedTo?.chainID
          ? APIURL.OKX_GETQUOTES
          : APIURL.CROSS_CHAIN_ROUTES;

      //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>SinglequotePayLoad<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

      let quotePayLoad = {
        chainId: storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID,
        amount: localeStringFunction(
          Number(tokenOneValue) *
            10 ** storeReduxInstance?.getState()?.okx?.selectedFrom?.decimal
        ),
        fromTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedFrom?.tokenAddress,
        toTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedTo?.tokenAddress,
      };

      //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>CrossChainQuotePayLoad<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
      // console.log(
      //   "firstlocaleStringFunction",
      //   localeStringFunction(
      //     toFunctionBigNumber(
      //       Number(tokenOneValue) *
      //         10 ** storeReduxInstance?.getState()?.okx?.selectedFrom?.decimal
      //     )
      //   ),
      //   tokenOneValue,
      //   storeReduxInstance?.getState()?.okx?.selectedFrom?.decimal,
      //   Number(
      //     localeStringFunction(
      //       Number(tokenOneValue) *
      //         10 ** storeReduxInstance?.getState()?.okx?.selectedFrom?.decimal
      //     )
      //   )
      // );
      let CrossChainQuotePayLoad = {
        amount: toFunctionBigNumber(
          Number(tokenOneValue) *
            10 ** storeReduxInstance?.getState()?.okx?.selectedFrom?.decimal
        ),
        fromChainId: storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID,
        toChainId: storeReduxInstance?.getState()?.okx?.selectedTo?.chainID,
        fromTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedFrom?.tokenAddress,
        toTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedTo?.tokenAddress,
        slippage: storeReduxInstance?.getState()?.okx?.slippage / 100,
      };
      const payLoad =
        storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID ==
        storeReduxInstance?.getState()?.okx?.selectedTo?.chainID
          ? quotePayLoad
          : CrossChainQuotePayLoad;
      if (payLoad) {
        const { selectedFrom, selectedTo } =
          storeReduxInstance?.getState()?.okx;
        const result = await getQuotes(endPoint, payLoad, {});
        setTokenOneValue(
          selectedFrom?.chainID == selectedTo?.chainID
            ? tokenOneValue
            : tokenOneValue
        );
        dispatch(setOkx_quote_data(result?.data?.data[0]));

        setTokenTwoValue(
          selectedFrom?.chainID == selectedTo?.chainID
            ? toFunctionBigNumber(
                result?.data?.data[0]?.toTokenAmount / 10 ** selectedTo?.decimal
              )
            : toFunctionBigNumber(
                result?.data?.data[0]?.routerList[0]?.toTokenAmount /
                  10 ** result?.data?.data[0]?.toToken?.decimals
              )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const okxSwap = async () => {
    try {
      const endPoint =
        storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID ==
        storeReduxInstance?.getState()?.okx?.selectedTo?.chainID
          ? APIURL.OKX_SWAP
          : APIURL.CROSS_CHAIN_SWAP;

      let swapPayload: any = {
        chainId: storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID,
        amount:
          tokenOneValue *
          10 ** storeReduxInstance?.getState()?.okx?.selectedFrom?.decimal,
        fromTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedFrom?.tokenAddress,
        toTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedTo?.tokenAddress,
        slippage: storeReduxInstance?.getState()?.okx?.slippage / 100,
        userWalletAddress: walletAddress,
        referrerAddress: referrerAddressOwnerOkx,
        feePercent: feePercentOkx,
      };

      let payloadCrossChain: any = {
        amount:
          tokenOneValue *
          10 ** storeReduxInstance?.getState()?.okx?.selectedFrom?.decimal,
        fromChainId: storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID,
        toChainId: storeReduxInstance?.getState()?.okx?.selectedTo?.chainID,
        fromTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedFrom?.tokenAddress,
        toTokenAddress:
          storeReduxInstance?.getState()?.okx?.selectedTo?.tokenAddress,
        slippage: storeReduxInstance?.getState()?.okx?.slippage / 100,
        userWalletAddress: walletAddress,
        bridgeId:
          storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID !=
            storeReduxInstance?.getState()?.okx?.selectedTo?.chainID &&
          okxQuoteData &&
          okxQuoteData?.routerList[0]?.router?.bridgeId,
        receiveAddress: walletAddress,
        feePercent: feePercentOkx,
        referrerAddress: referrerAddressOwnerOkx,
      };
      const payLoad =
        storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID ==
        storeReduxInstance?.getState()?.okx?.selectedTo?.chainID
          ? swapPayload
          : payloadCrossChain;
      const result = await okxSwapApi(endPoint, payLoad, {});
      if (result) setDataTx(result[0]);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const okxSwapFunction = async () => {
    try {
      if (walletAddress) {
        let nativeSymbol = getNativeSymbolFrom();
        if (selectedFrom?.symbol == nativeData[nativeSymbol]) {
          const dataTx = await okxSwap();
          if (dataTx) {
            setTransactionData({
              status: false,
              txnHash: "",
              code: false,
            });
            setShow(true);
            setShowTransactionModal(true);
            const swapResponse = await swapSendTransactionFunction(
              walletAddress,
              dataTx,
              walletProvider
            );
            if (swapResponse?.status) {
              setTransactionData({
                status: true,
                txnHash: swapResponse?.transactionHash,
                code: false,
              });
              setTxHash(swapResponse?.transactionHash);
              await init();
              await TokenBalanceSecond();
              toast.success("swap Success!");
            } else {
              setTransactionData({
                status: false,
                txnHash: "",
                code: true,
              });
            }
          }
        } else {
          const ApprovalAddressTokens = ApprovalAddress[selectedFrom?.chainID];
          const approveResult = await okxSwap();
          const allownace = await getAllowanceFunction(
            selectedFrom?.tokenAddress,
            ApprovalAddressTokens,
            walletAddress,
            walletProvider
          );

          let amount = approveResult[0]?.routerResult?.fromTokenAmount
            ? localeStringFunction(
                tokenOneValue *
                  10 ** approveResult[0]?.routerResult?.fromToken?.decimal
              )
            : localeStringFunction(approveResult[0]?.fromTokenAmount);

          if (Number(allownace) < Number(amount)) {
            setTransactionData({
              status: false,
              txnHash: "",
              code: false,
            });
            setShow(true);
            setShowTransactionModal(true);
            const result = await ApprovalSendTransactionFunction(
              walletAddress,
              ApprovalAddressTokens,
              amount,
              selectedFrom?.tokenAddress,
              walletProvider
            );
            if (result?.status) {
              const dataTx = await okxSwap();
              if (dataTx) {
                const swapResponse = await swapSendTransactionFunction(
                  walletAddress,
                  dataTx,
                  walletProvider
                );
                if (swapResponse?.status) {
                  setTransactionData({
                    status: true,
                    txnHash: swapResponse?.transactionHash,
                    code: false,
                  });
                  setTxHash(swapResponse?.transactionHash);
                  await init();
                  await TokenBalanceSecond();
                  toast.success("swap Success!");
                } else {
                  setTransactionData({
                    status: false,
                    txnHash: "",
                    code: true,
                  });
                }
              }
            } else {
              setTransactionData({
                status: false,
                txnHash: "",
                code: true,
              });
            }
          } else {
            setTransactionData({
              status: false,
              txnHash: "",
              code: false,
            });
            setShow(true);
            setShowTransactionModal(true);
            const dataTx = await okxSwap();
            if (dataTx) {
              const swapResponse = await swapSendTransactionFunction(
                walletAddress,
                dataTx,
                walletProvider
              );
              if (swapResponse?.status) {
                setTransactionData({
                  status: true,
                  txnHash: swapResponse?.transactionHash,
                  code: false,
                });
                setTxHash(swapResponse?.transactionHash);
                await init();
                await TokenBalanceSecond();
                toast.success("swap Success!");
              } else {
                setTransactionData({
                  status: false,
                  txnHash: "",
                  code: true,
                });
              }
            }
          }
        }
      } else {
        toast?.info("Please connect your wallet first!");
      }
    } catch (error: any) {
      setTransactionData({
        status: false,
        txnHash: "",
        code: true,
      });
      toast?.error(error?.message);
      console.log(error);
    }
  };

  const getImageFunction = (address: any) => {
    const result = allTokenList?.find(
      (data: any) => data?.tokenAddress == address
    );
    return !result
      ? "https://static.okx.com/cdn/wallet/logo/BNB-20220308.png"
      : result?.icon;
  };
  useEffect(() => {
    if (walletAddress) {
      setTimeout(() => {
        dispatch(saveWalletProvider(walletProvider));
        init();
        TokenBalanceSecond();
      }, 2000);
    } else {
      setTokenOneBalance("0");
      setTokenTwoBalance("0");
      dispatch(setOkx_quote_data(""));
    }
  }, [walletAddress, selectedFrom, selectedTo]);
  const init = async () => {
    if (walletAddress) {
      let oneBalance: any;
      let symbol;
      symbol = getNativeSymbolFrom();
      let networkName =
        storeReduxInstance?.getState()?.okx?.selectedFrom?.name ==
        "BNB Smart Chain"
          ? "Binance Coin"
          : storeReduxInstance?.getState()?.okx?.selectedFrom?.name;
      if (
        storeReduxInstance?.getState()?.okx?.selectedFrom?.symbol ==
          nativeData[
            symbol
              ? symbol
              : storeReduxInstance?.getState()?.okx?.selectedFrom?.symbol
          ] &&
        networkName ==
          networkChainName[
            storeReduxInstance?.getState()?.okx?.selectedFrom?.chainID
          ]
      ) {
        oneBalance = await getNativeBalance(walletAddress, walletProvider);
        setTokenOneBalance(oneBalance?.calculatedBalance);
      } else {
        oneBalance = await OkxContractServices?.getTokenBalance(
          storeReduxInstance?.getState()?.okx?.selectedFrom?.tokenAddress,
          walletAddress,
          walletProvider
        );

        setTokenOneBalance(oneBalance);
      }
    }
  };

  const TokenBalanceSecond = async () => {
    if (walletAddress) {
      let oneBalance: any;
      const symbol = getNativeSymbolTo();
      let networkName =
        storeReduxInstance?.getState()?.okx?.selectedFrom?.name ==
        "BNB Smart Chain"
          ? "Binance Coin"
          : storeReduxInstance?.getState()?.okx?.selectedFrom?.name;
      if (
        storeReduxInstance?.getState()?.okx?.selectedTo?.symbol ==
          nativeData[symbol] &&
        storeReduxInstance?.getState()?.okx?.selectedTo?.chainID ==
          window?.ethereum?.networkVersion
      ) {
        oneBalance = await getNativeBalance(walletAddress, walletProvider);
        setTokenTwoBalance(oneBalance?.calculatedBalance);
      } else {
        oneBalance = await OkxContractServices?.getTokenBalance(
          storeReduxInstance?.getState()?.okx?.selectedTo?.tokenAddress,
          walletAddress,
          walletProvider
        );
        setTokenTwoBalance(oneBalance);
      }
    }
  };

  const maxAmountFunction = () => {
    setTokenOneValue("");
    setTokenTwoValue("");
    let lessAmount;
    if (tokenOneBalance) {
      if (Number(tokenOneBalance) > 0.01) {
        let symbol = getNativeSymbolFrom();
        if (
          storeReduxInstance?.getState()?.okx?.selectedFrom?.symbol ==
          nativeData[symbol]
        ) {
          lessAmount = Number(tokenOneBalance) - 0.01;
          delayedQuery(lessAmount);
        } else {
          lessAmount = Number(tokenOneBalance);
          setTokenOneValue(lessAmount);
          delayedQuery(lessAmount);
        }
      } else {
        setTokenOneValue("");
        setTokenTwoValue("");
        dispatch(setOkx_quote_data(""));
      }
    } else {
      setTokenOneValue("");
      setTokenTwoValue("");
      dispatch(setOkx_quote_data(""));
    }
  };

  document.addEventListener(EVENTS.LOGIN_SUCCESS, async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await init();
    TokenBalanceSecond();
  });

  const getNativeSymbolFrom = () => {
    const res = storeReduxInstance
      ?.getState()
      ?.okx?.allTokenList?.find(
        (data: any) =>
          data?.symbol ==
          storeReduxInstance?.getState()?.okx?.selectedFrom?.symbol
      );
    return res?.symbol;
  };

  const getNativeSymbolTo = () => {
    const res = storeReduxInstance
      ?.getState()
      ?.okx?.allTokenList?.find(
        (data: any) =>
          data?.symbol ==
          storeReduxInstance?.getState()?.okx?.selectedTo?.symbol
      );
    return res?.symbol;
  };

  const handleSwitchCurrencies = async () => {
    // setTokenOneValue("");
    setTokenTwoValue("");
    if (selectedFrom?.chainID != selectedTo?.chainID) {
      const response = await switchNetwork(
        networkChainName[selectedTo?.chainID],
        dispatch,
        walletProvider
      );
      if (!response) {
        return;
      }
    }
    dispatch(setSaveSelectedFrom(selectedTo));
    dispatch(setSaveSelectedTo(selectedFrom));
    dispatch(setSaveOkxChainName(networkChainName[selectedTo?.chainID]));
    setTokenOneValue(tokenTwoValue);
    delayedQuery(tokenTwoValue.toString());
    setTokenOneBalance(tokenTwoBalance);
    setTokenTwoBalance(tokenOneBalance);

    amountIn === "TK1" ? setAmountIn("TK2") : setAmountIn("TK1");
  };

  const closeTransactionModal = () => {
    setShowTransactionModal(false);
    setShow(false);
  };
  return (
    <>
      <section className="chainPage">
        <Row>
          <Col lg={6}>
            <div className="chain_left">
              <h2>Routing</h2>
              {okxQuoteData ? (
                <div className="routing">
                  <h4>Routing</h4>
                  {okxQuoteData?.routerList?.length > 0 &&
                  okxQuoteData?.routerList[0]?.fromDexRouterList != 0 ? (
                    <ul>
                      <li>
                        <div>
                          <span className={"token_icon"}>
                            <img src={selectedFrom?.icon} alt="eth" />
                          </span>
                          <span className={"arrow"} />

                          {okxQuoteData?.routerList[0]?.fromDexRouterList
                            .length > 0 &&
                            okxQuoteData?.routerList[0]?.fromDexRouterList[0]?.subRouterList
                              ?.slice(
                                0,
                                okxQuoteData?.routerList[0]
                                  ?.fromDexRouterList[0]?.subRouterList
                                  ?.length > 1
                                  ? okxQuoteData?.routerList[0]
                                      ?.fromDexRouterList[0]?.subRouterList
                                      ?.length - 1
                                  : okxQuoteData?.routerList[0]
                                      ?.fromDexRouterList[0]?.subRouterList
                                      ?.length
                              )
                              ?.map((info: any) => {
                                return (
                                  <>
                                    <div className={"routing_details"}>
                                      {info?.dexProtocol?.map((data: any) => {
                                        return (
                                          <>
                                            <h4>{data?.percent}%</h4>
                                            <div>
                                              <span className={"token_icon"}>
                                                <img
                                                  src={getImageFunction(
                                                    info?.toToken
                                                      ?.tokenContractAddress
                                                  )}
                                                  alt="eth"
                                                />
                                              </span>
                                              <p>
                                                {info?.toToken?.tokenSymbol}
                                              </p>
                                            </div>
                                            <h5>
                                              {data?.dexName} {data?.percent}%
                                            </h5>
                                          </>
                                        );
                                      })}
                                    </div>
                                    <span className={"arrow"} />
                                  </>
                                );
                              })}
                          <span className={"token_icon"}>
                            <img
                              src={
                                okxQuoteData?.routerList[0]?.fromDexRouterList
                                  .length > 0 && selectedTo?.icon
                              }
                              alt="btc"
                            />
                          </span>
                        </div>
                      </li>
                    </ul>
                  ) : okxQuoteData?.dexRouterList?.length > 0 ? (
                    <ul>
                      <li>
                        <div>
                          <span className={"token_icon"}>
                            <img
                              src={
                                okxQuoteData?.dexRouterList?.length > 0 &&
                                selectedFrom?.icon
                              }
                              alt="eth"
                            />
                          </span>
                          <span className={"arrow"} />

                          {okxQuoteData?.dexRouterList?.length > 0 &&
                            okxQuoteData?.dexRouterList[0]?.subRouterList
                              ?.slice(
                                0,
                                okxQuoteData?.dexRouterList[0]?.subRouterList
                                  ?.length > 1
                                  ? okxQuoteData?.dexRouterList[0]
                                      ?.subRouterList?.length - 1
                                  : okxQuoteData?.dexRouterList[0]
                                      ?.subRouterList?.length
                              )
                              ?.map((info: any) => {
                                return (
                                  <>
                                    <div className={"routing_details"}>
                                      {info?.dexProtocol?.map((data: any) => {
                                        return (
                                          <>
                                            <h4>{data?.percent}%</h4>
                                            <div>
                                              <span className={"token_icon"}>
                                                <img
                                                  src={getImageFunction(
                                                    info?.toToken
                                                      ?.tokenContractAddress
                                                  )}
                                                  alt="eth"
                                                />
                                              </span>
                                              <p>
                                                {info?.toToken?.tokenSymbol}
                                              </p>
                                            </div>
                                            <h5>
                                              {data?.dexName} {data?.percent}%
                                            </h5>
                                          </>
                                        );
                                      })}
                                    </div>
                                    <span className={"arrow"} />
                                  </>
                                );
                              })}
                          <span className={"token_icon"}>
                            <img
                              src={
                                okxQuoteData?.dexRouterList?.length > 0 &&
                                selectedTo?.icon
                              }
                              alt="btc"
                            />
                          </span>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <>
                      <p className="text-center">No Routing Information</p>
                    </>
                  )}
                </div>
              ) : (
                <div className="chain_norouting">
                  <h3>No Routing Information</h3>
                </div>
              )}

              <div className="chain_table">
                <div className="table_head">
                  <h5>Cross Chain Swap</h5>
                </div>
                <div className="table_in">
                  <CommonTable
                    className="tableTrade last_btnfix"
                    fields={tableheading}
                  >
                    {okxQuoteData?.dexRouterList?.length > 0
                      ? okxQuoteData?.dexRouterList
                          .slice(0, 8)
                          .map((item: any, index: any) => {
                            return (
                              <tr key={index}>
                                {/* <td /> */}
                                {/* {item?.subRouterList[0]?.dexProtocol?.map(
                                  (data: any) => {
                                    console.log("datadata", data);
                                    return <td>{data?.dexName}</td>;
                                  }
                                )} */}
                                <td>
                                  {
                                    item?.subRouterList[0]?.dexProtocol[0]
                                      ?.dexName
                                  }
                                </td>
                                <td>
                                  {fixAndConvertFunction(
                                    okxQuoteData?.toTokenAmount /
                                      10 ** selectedTo?.decimal
                                  )}{" "}
                                  {item?.subRouterList[0]?.toToken?.tokenSymbol}
                                </td>
                                <td>--</td>
                                {/* <td /> */}
                              </tr>
                            );
                          })
                      : okxQuoteData?.routerList?.length > 0 &&
                        okxQuoteData?.routerList[0]?.fromDexRouterList != 0
                      ? okxQuoteData?.routerList[0]?.fromDexRouterList
                          .slice(0, 8)
                          .map((item: any, index: any) => {
                            return (
                              <tr key={index}>
                                {/* <td /> */}
                                {/* {item?.subRouterList[0]?.dexProtocol?.map(
                                  (data: any) => {
                                    console.log(
                                      "datadata",
                                      item?.subRouterList[0]?.dexProtocol[0]
                                        ?.dexName,
                                      data[Object.keys(data)[0]],
                                      Object.keys(data)[0]
                                    ); */}
                                {/* // return ( */}
                                <td>
                                  {
                                    item?.subRouterList[0]?.dexProtocol[0]
                                      ?.dexName
                                  }
                                </td>
                                {/* ; */}
                                {/* );
                                  }
                                )} */}
                                <td>
                                  {fixAndConvertFunction(
                                    okxQuoteData?.routerList[0]?.toTokenAmount /
                                      10 ** okxQuoteData?.toToken?.decimals
                                  )}{" "}
                                  {item?.subRouterList[0]?.toToken?.tokenSymbol}
                                </td>
                                <td>
                                  {okxQuoteData?.routerList[0]?.router
                                    ?.crossChainFee
                                    ? fixAndConvertFunction(
                                        okxQuoteData?.routerList[0]?.router
                                          ?.crossChainFee
                                      )
                                    : "--"}
                                </td>
                                {/* <td /> */}
                              </tr>
                            );
                          })
                      : null}
                  </CommonTable>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="cardswap">
            <div className="addCardBox">
              <div className="addCard">
                <div className="addCard_heading">
                  <h3 className="titleHeading">OKX Cross Chain Swap</h3>
                  <SettingOverlayOKX />
                </div>
                <div className="addCard_tokenvalues">
                  <ActiveTokenCard
                    setTokenOneValue={setTokenOneValue}
                    setTokenTwoValue={setTokenTwoValue}
                    keyokx="okx"
                    field="Field1"
                    balancevalue={tokenOneBalance}
                    balance={tokenOneBalance}
                    input={(value: any) => {
                      okxHandleChange(value);
                    }}
                    value={tokenOneValue}
                    maxFunction={maxAmountFunction}
                    dollarVal={tk1DollarValue}
                    shimmer={shimmerState}
                    showTokensSelectModal={true}
                    // setTokenOneChainId={setTokenOneChainId}
                  />
                  <Button
                    className="swapBtn"
                    onClick={() => handleSwitchCurrencies()}
                  >
                    <DropUpswapIcon />
                  </Button>
                  <SecondaryTokenCard
                    setTokenTwoValue={setTokenTwoValue}
                    setTokenOneValue={setTokenOneValue}
                    keyokx="okx"
                    balancevalue={tokenTwoBalance}
                    field="Field2"
                    showTokensSelectModal={true}
                    balance={tokenBalance}
                    input={(value: any) => {
                      okxHandleChange(value);
                    }}
                    value={tokenTwoValue}
                    maxFunction={maxAmountFunction}
                    dollarVal={tk2DollarValue}
                    shimmer={shimmerState}
                    setTokenTwoIconnew={setTokenTwoIconnew}
                    setOkxTokenTwoAddress={setOkxTokenTwoAddress}
                    setTwoDecinmal={setTwoDecinmal}
                    // setTokenOneChainId={setTokenOneChainId}
                  />
                </div>
                <div className="slip_value">
                  <h3>Slippage</h3>
                  <h3>{`${
                    slippagePercentage ? slippagePercentage : "0.5"
                  }%`}</h3>
                </div>
                <div className="swap_btn">
                  <Button
                    disabled={
                      !walletAddress ||
                      Number(tokenOneBalance) == 0 ||
                      Number(tokenOneValue) > Number(tokenOneBalance)
                    }
                    fluid
                    onClick={okxSwapFunction}
                  >
                    Swap
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {show && (
        <TxnConfirmation
          show={show}
          transactionData={transactionData}
          handleClose={() => setShow(false)}
        />
      )}
    </>
  );
};

export default ChainCross;
