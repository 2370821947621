import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PlusIcon } from "../../../../assets/icons/svgicons";
import { ROUTES, zeroAddress } from "../../../../utils/constants";
import Button from "../../../common/Button/Button";
import CommonModal from "../../../common/Modals/CommonModal/CommonModal";
import RemoveLiquidity from "../../../common/RemoveLiquidity/RemoveLiquidity";
import SettingOverlay from "../../../common/SettingOverlay/SettingOverlay";
import TokenselectModal from "../../../common/TokenselectModal/TokenselectModal";
import "./Index.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { LP_DATA, TOKEN_DATA } from "../../../../interfaces/Liquidity";
import {
  cryptoDecimals,
  divideBigNumber,
  toFixer,
} from "../../../../utils/helpers";
import {
  fetchShareOfUser,
  getTokensFromPair,
  getTotalSupplyOfLp,
} from "../../../../services/contractServices/contractCallServices";
import { useWalletConnect } from "../../../../CustomHook/useWalletConnect";
import { setImportedLps } from "../../../../features/theme/user.slice";
import { toast } from "../../../common/Toasts/Toast";
import TokensModal from "../../../common/Modals/TokensModal/TokensModal";

const AddLiquidity = () => {
  const {
    tokenOne,
    tokenTwo,
    tokenList,
  }: { tokenOne: TOKEN_DATA; tokenTwo: TOKEN_DATA; tokenList: TOKEN_DATA[] } =
    useAppSelector((store: any) => store?.token);
  const { importedLp, walletAddress } = useAppSelector(
    (state: any) => state?.user
  );
  const [lpInfo, setlpInfo] = useState<LP_DATA>({
    tokenBReceive: "",
    tokenAReceive: "",
    userLpBalance: "",
  });
  const [showToken, setShowToken] = useState<any>({ show: false, data: {} });
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { walletProvider } = useWalletConnect();
  const handleImportTokens = async () => {
    if (tokenOne && tokenTwo) {
      // dispatch(setStartLoading());
      const { totalSupply, pairAddress } = await getTotalSupplyOfLp({
        tokenOneAddress: tokenOne?.address,
        tokenTwoAddress: tokenTwo?.address,
        dispatch,
        walletProvider,
      });
      console.log("pairdaadaa", pairAddress);
      if (pairAddress !== zeroAddress) {
        if (
          importedLp?.some((obj: any) => obj.pair == pairAddress.toLowerCase())
        ) {
          // dispatch(setStopLoading());
          toast.info("Pool already imported!");
          return;
        } else {
          const { tokenA, tokenB } = await getTokensFromPair(
            pairAddress,
            dispatch,
            walletProvider
          );

          const { share, tokenBReceive, tokenAReceive, userLpBalance } =
            await fetchShareOfUser(
              pairAddress,
              tokenA,
              tokenB,
              dispatch,
              totalSupply,
              tokenA.toLowerCase() == tokenOne?.address.toLowerCase()
                ? tokenOne?.decimals
                : tokenTwo?.decimals,
              tokenB.toLowerCase() == tokenOne?.address.toLowerCase()
                ? tokenOne?.decimals
                : tokenTwo?.decimals,
              walletProvider
            );
          console.log("userLpBalnc", userLpBalance);
          const tokenAInfo = tokenList?.find((elem) => elem?.address == tokenA);
          const tokenBInfo = tokenList?.find((elem) => elem?.address == tokenB);

          const lpdata = {
            pair: pairAddress.toLowerCase(),
            token0: tokenA,
            token1: tokenB,
            share,
            tokenBReceive,
            tokenAReceive,
            userLpBalance,
            tokenAInfo,
            tokenBInfo,
          };

          dispatch(setImportedLps([...importedLp, lpdata]));
          // dispatch(setStopLoading());
          // navigate("/trade/liquidity", { state: true });
          setShow(false);
        }
      } else {
        toast.error("No Pool Found");
        // setCurrentPairAddress("");
        // dispatch(setStopLoading());
      }
    }
  };

  return (
    <>
      <div className="liquidityAdd">
        {/* <Row>
                    <Col lg={6}>
                        <div className='liquidityAdd_faq'>
                            <h2>Liquidity</h2>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><span>Yield Farming or Liquidity mining</span> <ArrowdownGrayIcon /></Accordion.Header>
                                    <Accordion.Body>
                                        <p>Account abstraction is a concept in blockchain technology that enables the user to interact with dApps without
                                            worrying about managing seed phrases and private keys.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><span>Efficient Governance</span> <ArrowdownGrayIcon /></Accordion.Header>
                                    <Accordion.Body>
                                        <p>Account abstraction is a concept in blockchain technology that enables the user to interact with dApps without
                                            worrying about managing seed phrases and private keys.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><span>Minting synthetic assets</span> <ArrowdownGrayIcon /></Accordion.Header>
                                    <Accordion.Body>
                                        <p>Account abstraction is a concept in blockchain technology that enables the user to interact with dApps without
                                            worrying about managing seed phrases and private keys.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><span>Insurance sector</span> <ArrowdownGrayIcon /></Accordion.Header>
                                    <Accordion.Body>
                                        <p>Account abstraction is a concept in blockchain technology that enables the user to interact with dApps without
                                            worrying about managing seed phrases and private keys.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><span>Tranching</span> <ArrowdownGrayIcon /></Accordion.Header>
                                    <Accordion.Body>
                                        <p>Account abstraction is a concept in blockchain technology that enables the user to interact with dApps without
                                            worrying about managing seed phrases and private keys.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                    <Col lg={6}> */}
        <div className="addCardBox">
          <div className="addCard">
            <div className="addCard_heading">
              <h3 className="titleHeading">Liquidity</h3>
              <SettingOverlay />
            </div>
            <div className="addLiquidity">
              <div className="activeliauidity">
                <h6>Add Liquidity To Receive LP Tokens</h6>
                <Button
                  className="addBtn whiteBorder"
                  onClick={() => navigate(`/liquidity/liquidity-form`)}
                >
                  Add Liquidity
                </Button>
                <Button
                  className="mt-3 addBtn whiteBorder"
                  onClick={() => setShow(true)}
                >
                  Remove Liquidity
                </Button>
                <RemoveLiquidity
                  show={showToken}
                  handleClose={() => setShowToken({ show: false, data: {} })}
                />
              </div>
              {/* <p className="infofound">No Liquidity found.</p> */}
              {importedLp?.length > 0
                ? importedLp?.map((item: any, key: number) => (
                  <div className="yourLiquidity">
                    <h6 className="yourLiquidityHeading">
                      <span>Your Liquidity</span>
                      <button
                        onClick={() => {
                          setShowToken({ show: true, data: item });
                        }}
                      >
                        Remove LP
                      </button>
                    </h6>

                    {/*  */}
                    <div className="tokens_in_wallet">
                      <div className="token_num">
                        <ul>
                          <li>
                            <p>
                              <span>
                                <img src={item?.tokenAInfo?.icon} alt="eth" />
                                <img
                                  src={item?.tokenBInfo?.icon}
                                  alt="bitcoin"
                                />
                              </span>
                              {`${item?.tokenAInfo?.symbol} /
                                  ${item?.tokenBInfo?.symbol}`}
                            </p>
                            <h6>
                              {divideBigNumber(item?.userLpBalance, 18) || 0}
                            </h6>
                          </li>
                        </ul>
                      </div>
                      <ul>
                        <li>
                          <p>{item?.tokenAInfo?.symbol}</p>
                          <h6>{cryptoDecimals(item?.tokenAReceive || 0)}</h6>
                        </li>
                        <li>
                          <p>{item?.tokenBInfo?.symbol}</p>
                          <h6>{cryptoDecimals(item?.tokenBReceive || 0)}</h6>
                        </li>
                      </ul>
                    </div>
                    {/*  */}
                    {/* <p>
                  You joined a pool, but can't view it?{" "}
                  <Link to={ROUTES.LIQUIDITY}>Bring in Now.</Link>
                </p>
                <p>
                  Alternatively, unstack your STC tokens to view them here if
                  you stacked them in a farm.
                </p> */}
                  </div>
                ))
                : null}
            </div>
          </div>
        </div>
        <CommonModal
          show={show}
          handleClose={() => setShow(false)}
          className="remove_liquidity_select_token"
          heading="Select Tokens"
        >
          <div className="remove_liquidity_select_token_input">
            <TokensModal field="Field1" />
          </div>
          <button className="plusBtn">
            <PlusIcon />
          </button>
          <div className="remove_liquidity_select_token_input">
            <TokensModal field="Field2" tokenActive />
          </div>
          <p className="select_text">Select a token to find your liquidity.</p>
          <Button
            fluid
            onClick={() => {
              handleImportTokens();
            }}
            disabled={!walletAddress}
          >
            Import
          </Button>
        </CommonModal>
        {/* </Col>
                </Row> */}
      </div>
    </>
  );
};

export default AddLiquidity;
