import { useState, useEffect } from "react";
import {
  SearchIcon,
  StarIcon,
  StarIconFilled,
} from "../../../assets/icons/svgicons";
import Button from "../../common/Button/Button";
import CommonTable from "../../common/CommonTable/CommonTable";
import "./Trade.scss";
import BuysellModal from "../../common/Modals/BuysellModal/BuysellModal";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { MarketServices } from "../../../services/MarketService/marketService";
import { toast } from "../../common/Toasts/Toast";
import { marketDataUpdate } from "../../../features/theme/market.slice";

const Trade = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const params = useParams();
  let navigate = useNavigate();
  const {
    walletAddress,
    transactionCounter,
    slippage,
  }: { walletAddress: string; transactionCounter: boolean; slippage: number } =
    useAppSelector((store: any) => store?.user);
  let dispatch = useAppDispatch();
  // const walletAddress = useSelector((state) => state.persist.walletAddress);

  const intialPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  // const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [currencyType, setcurrencyType] = useState({
    receivingCurrency: "htx",
    receivableCurrency: "usdt",
    symbol: "htxusdt",
    name: "htx/usdt",
  });
  const [marketData, setMarketData] = useState([]);
  const [favData, setFavData] = useState([]);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(intialPagination);
  // const [marketData, setMarketData] = useState([]);

  // for sorting

  const [sortColumn, setSortColumn] = useState("asc");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // Handle Tab Navigation
  const handleNavigate = (receivableCurrency: any) => {
    setSearch("");
    setPagination(intialPagination);
    navigate(`/market/${receivableCurrency}`);
  };

  // Fetch Market Data
  const getMarketData = async (tab: any, current: any) => {
    ////  dispatch(startLoading());
    let data = {
      receivableCurrency: tab || "usdt",
      page: current || pagination.current || 1,
      limit: pagination.pageSize,
      search: search || "",
    };
    try {
      let marketRes: any;
      if (tab == "fav") {
        marketRes = await MarketServices.getFavouriteService({
          page: pagination.current || 1,
          limit: pagination.pageSize,
          search: search || "",
          UserAddress: walletAddress,
        });
      } else {
        marketRes = await MarketServices.findDesiredTokenService(data);
      }
      if (marketRes?.data?.data?.length > 0) {
        setMarketData(marketRes?.data?.data);
        setPagination({
          ...pagination,
          total: Number(marketRes?.data?.totalCount),
          current: current,
        });
        //  dispatch(stopLoading());
      } else {
        setMarketData([]);
        //  dispatch(stopLoading());
      }
    } catch (error) {
      console.log(error);
      //  dispatch(stopLoading());
    }
  };

  // Get Favourites
  const getFav = async () => {
    let data = {
      UserAddress: walletAddress,
    };
    try {
      let favRes: any = await MarketServices.getFavouriteService(data);
      if (favRes?.data?.data?.length > 0) {
        setFavData(favRes?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Add Favourites
  const setFavourite = async (recard: any) => {
    let data = {
      UserAddress: walletAddress,
      favSymbol: recard?.symbol || recard?.favSymbol,
      receivingCurrency: recard.receivingCurrency,
      receivableCurrency: recard.receivableCurrency,
      name: recard?.name,
    };
    //  dispatch(startLoading());
    let SetFavRes: any = await MarketServices.setFavouriteService(data);
    if (SetFavRes?.data?.error == false) {
      toast.info(`${SetFavRes.data.message}`);
      getMarketData(params.tab, "");
      getFav();
    } else {
      //  dispatch(stopLoading());
      if (!walletAddress) {
        return toast.info("Connect wallet first!");
      }
      setShow1(walletAddress ? false : true);
    }
  };
  // Remove Favourites
  const removeFavourite = async (recard: any) => {
    //  dispatch(startLoading());
    let data = {
      UserAddress: walletAddress,
      favSymbol: recard.symbol || recard?.favSymbol,
      receivingCurrency: recard.receivingCurrency,
      receivableCurrency: recard.receivableCurrency,
      name: recard?.name,
    };
    let removeFavRes: any = await MarketServices.removeFavouriteService(data);
    if (removeFavRes?.data?.error == false) {
      setFavData([]);
      toast.info(`${removeFavRes?.data?.message}`);
      getFav();
      getMarketData(params.tab, "");
    } else {
      //  dispatch(stopLoading());
      toast.info("Please connect your wallet first!");
    }
  };
  // Compare Favourites with marketData
  let compareFav = (favData: any, nameRecard: any) => {
    let favorite = false;
    favData.filter((object: any) => {
      if (object?.name == nameRecard) {
        favorite = true;
      }
    });
    return favorite;
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    setPagination({
      ...pagination,
      current: current,
      pageSize: pageSize,
    });
    //  dispatch(startLoading());
    getMarketData(params.tab, current);
  };

  const handleSort = (columnName: any) => {
    if (sortColumn === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };
  const sortData = (data: any) => {
    if (!sortColumn) {
      return data;
    }

    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      if (sortOrder === "asc") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    });

    return sortedData;
  };
  // Search
  useEffect(() => {
    // dispatch(startLoading());
    const getData = setTimeout(() => {
      getMarketData(params.tab, "");
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useEffect(() => {
    // dispatch(startLoading());
    getMarketData(params.tab, "");
    getFav();
  }, [params.tab]);

  const sortedData = sortData(marketData);
  const tableData = [
    {
      symbol: "PEPE/USDT",
      lastPrice: "0.000001225167",
      turn_over: "948621.16",
      volume: "776904727464.01",
      isFavorite: false,
    },
    {
      symbol: "LPT/USDT",
      lastPrice: "7.889",
      turn_over: "4320693.60",
      volume: "547364.16",
      isFavorite: true,
    },
    {
      symbol: "PEPE/USDT",
      lastPrice: "0.000001225167",
      turn_over: "948621.16",
      volume: "776904727464.01",
      isFavorite: true,
    },
    {
      symbol: "PEPE/USDT",
      lastPrice: "0.000001225167",
      turn_over: "948621.16",
      isFavorite: false,
      volume: "776904727464.01",
    },
    {
      symbol: "PEPE/USDT",
      lastPrice: "0.000001225167",
      turn_over: "948621.16",
      isFavorite: false,
      volume: "776904727464.01",
    },
  ];
  const tableheading = [
    { label: "Favorite" },
    { label: "Symbol" },
    { label: "Last Price" },
    { label: "24th Turn Over" },
    { label: "24H Volume" },
    { label: "Action" },
  ];
  return (
    <>
      <div className="trade_page">
        {/* <HeadCard
                    title="Trade"
                    text="Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
                    image={trade_img}
                /> */}
        <div className="trade_table">
          <div className="table_head">
            <h5>Trade</h5>
            <div className="table_rightside">
              <div className="table_head_input">
                <span>
                  <SearchIcon />
                </span>
                <input type="text" placeholder="Search" />
              </div>
            </div>
          </div>
          <div className="table_in">
            <CommonTable
              className="tableTrade last_btnfix"
              fields={tableheading}
            >
              {sortedData.map((content: any) => (
                <tr key={content.lastPrice}>
                  <td>
                    {compareFav(favData, content?.name) == true ? (
                      <button
                        onClick={() => {
                          removeFavourite(content);
                        }}
                        title="Remove Favourite"
                        className="favoriteBtn active"
                      >
                        <StarIconFilled />
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setFavourite(content);
                        }}
                        title="Add Favourite"
                        className="favoriteBtn active"
                      >
                        <StarIcon />
                      </button>
                    )}
                  </td>
                  <td>{content.name}</td>
                  <td>{content.price}</td>
                  <td>{content.turnOver}</td>
                  <td>{content.volume}</td>
                  <td>
                    <Button
                      className="trade_btn"
                      onClick={() => {
                        setShow(true);
                        setcurrencyType({
                          receivingCurrency: content?.receivingCurrency,
                          receivableCurrency: content?.receivableCurrency,
                          symbol: content?.symbol,
                          name: content?.name,
                        });
              
                        dispatch(
                          marketDataUpdate({
                            receivingCurrency: content?.receivingCurrency,
                            receivableCurrency: content?.receivableCurrency,
                            symbol: content?.symbol,
                            name: content?.name,
                          })
                        );
                        setData(content);
                      }}
                    >
                      Trade
                    </Button>
                  </td>
                </tr>
              ))}
            </CommonTable>
          </div>
        </div>
      </div>
      <BuysellModal
        show={show}
        currencyType={currencyType}
        handleClose={() => setShow(false)}
      />
    </>
  );
};

export default Trade;
