import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store } from './app/store';
import { MetaMaskProvider } from '@metamask/sdk-react';

import './index.scss';
let persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>

  <Provider store={store}>
    <PersistGate loading={'Loading...'} persistor={persistor}>
        <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>,
);
