import "./Farm.scss";
import stcIcon from "../../../assets/icons/stcIcon.svg";
import {
  DownArrowIcon,
  NoRecordIcon,
  SearchIcon,
} from "../../../assets/icons/svgicons";
import Button from "../../common/Button/Button";
import Card from "../../common/Card/Card";
import CommonTable from "../../common/CommonTable/CommonTable";
import Ethcircle from "../../../assets/icons/Ethcircle.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getFarmData } from "../../../services/ApiServices/apiService";
import { cryptoDecimals } from "../../../utils/helpers";
import {
  enablePool,
  executeHarvesting,
} from "../../../services/stakingServices/stakingHelper";
import { useWalletConnect } from "../../../CustomHook/useWalletConnect";
import DepositModal from "../../common/Modals/DepositModal/DepositModal";
import WithdrawModal from "../../common/Modals/WithdrawModal/WithdrawModal";
import { getTokenAllowance } from "../../../services/LiquidityServices/AddLiquidityHelper";
import TxnModal from "../../common/Modals/TxnModal/TxnModal";
import Commonselect from "../../common/Select/Commonselect";

const Farm = () => {
  const [showDetails, setShowdetail] = useState(false);
  const [showDeposit, setShowDeposit] = useState({ show: false, data: {} });
  const [showWithdraw, setShowWithdraw] = useState({ show: false, data: {} });
  const [selectFilter, setSelectedFilter] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [showTable, setShowTable] = useState({
    showStatus: false,
    field: null,
  });
  const tableheading = [
    { label: "Pools" },
    { label: "APR" },
    { label: "Liquidity" },
    { label: "Earning" },
    { label: "Action" },
  ];
  const tableData = [
    {
      Iconsfirst: stcIcon,
      Iconsasecond: Ethcircle,
      tokenTitle: "SAITAMA-ETH",
      aprtitle: "0 %",
      liquidity: "$ 10358.7379",
      erningTitle: "0.0000 Token",
      erningSubvalue: "$ 0.0000",
      secPoolLabel: "Available LP",
      poollpvalue: "0.0000 LP",
      poolamount: "$ 0.0000",
      aprLabel: "Reward Limit",
      aprvalue: "23454900",
      liquiLabel: "Withdrawal Fees",
      liquivalue: "0.01%",
      dipositLabel: "Deposit Fees",
      dipositvalue: "0.01%",
      stakedLabel: "Staked LP",
      stakedvalue: "0.0 LP",
    },
  ];
  const dispatch = useAppDispatch();
  const {
    walletAddress,
    chainValues,
  }: { walletAddress: string; chainValues: any } = useAppSelector(
    (store: any) => store?.user
  );
  const { walletProvider } = useWalletConnect();
  const [show, setShow] = useState<boolean>(false);
  const [isSearchedTriggered, setIsSearchedTriggered] =
    useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({
    status: "",
    bodyText: "",
    title: "",
    txHash: "",
  });
//useEffect
  useEffect(() => {
    if (walletProvider || !walletAddress) fetchFarmData();
  }, [chainValues, walletProvider, walletAddress]);
  const [farmingDetails, setFarmingDetails] = useState<any>([]);
  const [filteredFarmingDetails, setFilteredFarmingDetails] = useState<any>([]);

  console.log("asasdpadada", walletAddress);
  const fetchFarmData = async () => {
    setLoading(true);
    const result = await getFarmData(
      chainValues?.symbol == "ETH"
        ? "ETHEREUM"
        : chainValues?.symbol == "BSC"
        ? "BINANCE"
        : "SAITACHAIN",
      walletAddress
    );
    const updatedData: any =
      result?.length > 0 &&
      (await Promise.all(
        result?.map(async (data: any) => {
          data.allowance = await getTokenAllowance({
            tokenAddress: data?.lpTokenAddress,
            dispatch,
            spender: data?.chefProxy,
            walletAddress,
            walletProvider,
          });
          return data;
        })
      ));
    setFarmingDetails(updatedData);
    setLoading(false);
  };
//enableFarmingPool
  const enableFarmingPool = async (poolData: any) => {
    console.log("PooolDtataa", poolData);
    const response = await enablePool({
      approveLpToken: poolData?.lpTokenAddress,
      proxyAddress: poolData?.chefProxy,
      walletProvider,
      walletAddress,
      dispatch,
    });
    if (response) {
      await fetchFarmData();
    }
  };
//handleharvest
  const handleharvest = async (poolData: any) => {
    setShow(true);
    const response = await executeHarvesting({
      tokenAddress: poolData?.tokenAddress,
      proxyAddress: poolData?.chefProxy,
      walletProvider,
      walletAddress,
      dispatch,
      fee: poolData?.platformfee,
      stakeType: poolData?.pid,
      setModalData,
    });
    if (response) {
      await fetchFarmData();
    }
  };
//comments
  const handleSearch = async (data: string) => {
    const key = data.toLowerCase();
    if (key !== "") {
      const filteredList: any = farmingDetails.filter((token: any) => {
        return (
          token.lpName.toLowerCase().includes(key) ||
          token.lpTokenAddress.toLowerCase().includes(key)
        );
      });
      console.log("asudaladad", filteredList);
      setFilteredFarmingDetails(filteredList);
      setIsSearchedTriggered(true);
    } else {
      setFilteredFarmingDetails([]);
      setIsSearchedTriggered(false);
    }
  };
//added comment
  const handleFilter = async (keyName: string) => {
    setSelectedFilter(keyName);
    const UserPoolData = JSON.parse(JSON.stringify(farmingDetails))?.sort(
      (a: any, b: any) => {
        if (keyName === "APR") {
          return b.apr - a.apr;
        } else if (keyName === "Staked Only") {
          return Number(b.userStaked) - Number(a.userStaked);
        } else if (keyName === "Earned") {
          return Number(b.earnedToken) - Number(a.earnedToken);
        }
      }
    );
    console.log("asdhuaksdlasduadssad", UserPoolData);
    setFarmingDetails(UserPoolData);
  };

  console.log("farimnsjs", farmingDetails);
  const poolOptions = [
    {
      label: "APR",
      value: "APR",
    },
    {
      label: "Earned",
      value: "Earned",
    },
    {
      label: "Staked Only",
      value: "Staked Only",
    },
  ];
  console.log("jdsaladadsada", farmingDetails);

  return (
    <>
      <section className="farm">
      {chainValues.label=="SBC"?<h1 style={{textAlign:"center", marginTop:"20px"}}>Coming Soon..</h1>:  <Card
          title="Farm"
          filters={
            <>
              <div className="search_input">
                <span>
                  <SearchIcon />
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {/* <Commonselect
                isSearchable={true}
                className="pool_select"
                placeholder="Pool"
                options={poolOptions}
              /> */}
              <Commonselect
                isSearchable={true}
                className="sort_by"
                placeholder="Sort By"
                options={poolOptions}
                onChange={(e: any) => handleFilter(e?.label)}
                value={selectFilter}
              />
            </>
          }
        >
                 
           <CommonTable
            loading={loading}
            className="tableFarm last_btnfix"
            fields={tableheading}
          >
            {(isSearchedTriggered ? filteredFarmingDetails : farmingDetails)
              ?.length > 0 ? (
              (isSearchedTriggered
                ? filteredFarmingDetails
                : farmingDetails
              )?.map((data: any, key: any) => (
data.lpName==="SAITAMA-ETH"?"":

                <>
                
                  <tr
                    className={
                      showTable?.showStatus && showTable?.field == key
                        ? "tableRowactive"
                        : ""
                    }
                  >
                    <td>
                      <div className="infoToken">
                        <div className="infoToken_icons">
                          <img src={data.pairTokenLogo} alt="icon" />
                          {/* <img src={data.Iconsasecond} alt="icon" /> */}
                        </div>
                        <h5>{data.lpName}</h5>
                      </div>
                    </td>
                    <td>{cryptoDecimals(data.apr ?? 0)} %</td>
                    <td>$ {cryptoDecimals(data.liquidity ?? 0)}</td>
                    <td>
                      <div className="earningText">
                        <h6>
                          {cryptoDecimals(data.earnedToken / 10 ** 18)} Token
                        </h6>
                        <p>${cryptoDecimals(data.earnedTokenInDollar)}</p>
                      </div>
                    </td>
                    <td>
                      <Button
                        className="without_bg_border arrowBtn"
                        onClick={() =>
                          setShowTable({
                            showStatus: !showTable?.showStatus,
                            field: key,
                          })
                        }
                      >
                        <DownArrowIcon />
                      </Button>
                    </td>
                  </tr>
                  {showTable?.showStatus && showTable?.field == key ? (
                    <tr
                      className={
                        showTable?.showStatus && showTable?.field == key
                          ? "tableRowactive tableRowActiveDesc"
                          : ""
                      }
                    >
                      <td>
                        <div className="line"></div>
                        <div className="secformData">
                          <label>Available LP</label>
                          <p>
                            {cryptoDecimals(data.availableLp / 10 ** 18) || 0}{" "}
                            LP
                          </p>
                          <p>
                            ${cryptoDecimals(data.availableLpInDollar) || 0}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="secformData">
                          <label>Reward Limit</label>
                          <p>
                            {cryptoDecimals(data.rewardPerBlock * 28800 * 365)}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="secformData">
                          <label>Withdrawal Fees</label>
                          <p>{data.withdrawalFees / 100} %</p>
                        </div>
                      </td>
                      <td colSpan={1}>
                        <div className="earningTable">
                          <div className="earningTable_left">
                            <div className="secformData">
                              <label>Deposit Fees</label>
                              <p>{data.depositFees / 100} %</p>
                            </div>
                            {data.allowance > 0 ? (
                              <>
                                <Button
                                  className="btnEnable small_btn"
                                  onClick={() =>
                                    setShowDeposit({ show: true, data: data })
                                  }
                                  disabled={
                                    Number(data.availableLp) == 0 ||
                                    !walletAddress
                                  }
                                >
                                  Deposit
                                </Button>

                                <Button
                                  className="btnEnable small_btn"
                                  onClick={() =>
                                    setShowWithdraw({
                                      show: true,
                                      data: data,
                                    })
                                  }
                                  disabled={
                                    Number(data.userStaked) == 0 ||
                                    !walletAddress
                                  }
                                >
                                  Withdraw
                                </Button>
                              </>
                            ) : (
                              <Button
                                className="btnEnable small_btn"
                                onClick={() => enableFarmingPool(data)}
                                disabled={!walletAddress}
                              >
                                Enable Farm
                              </Button>
                            )}

                            {/* <Button
                              className="btnEnable small_btn"
                              onClick={() => setShowdetail(true)}
                            >
                              Staking Records
                            </Button> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="earningTable_right">
                          <div className="secformData">
                            <label>Staked LP</label>
                            <p>{data.userStaked / 10 ** 18 || 0} LP</p>
                          </div>
                          <Button
                            className="btnEnable small_btn"
                            disabled={
                              Number(data.earnedToken) == 0 || !data.allowance
                            }
                            onClick={() => handleharvest(data)}
                          >
                            Harvest
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr className="spacingTr"></tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="no_record_found">
                  <h5>Farm Pools Coming Soon.</h5>
                </td>
              </tr>
            )}
          </CommonTable>
          
         
        </Card>}
      
      </section>
      {/* <UserStakingInfo
        show={showDetails}
        handleClose={() => setShowdetail(false)}
      /> */}
      <DepositModal
        show={showDeposit?.show}
        handleClose={() => {
          setShowDeposit({ show: false, data: {} });
        }}
        depositData={showDeposit?.data}
        fetchLatestData={fetchFarmData}
        transactionModal={setShow}
        transactionModalData={setModalData}
      />
      <WithdrawModal
        show={showWithdraw?.show}
        handleClose={() => {
          setShowWithdraw({ show: false, data: {} });
        }}
        withdrawData={showWithdraw?.data}
        fetchLatestData={fetchFarmData}
        transactionModal={setShow}
        transactionModalData={setModalData}
      />
      {show ? (
        <TxnModal
          show={show}
          handleClose={() => {
            setShow(false);
          }}
          data={modalData}
        />
      ) : null}
    </>
  );
};

export default Farm;
