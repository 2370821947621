import { useEffect, useState } from "react";
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import {
  ArrowdownGrayIcon,
  InfoBlackIcon,
  SearchIcon,
  ShareIcon,
} from "../../../assets/icons/svgicons";
import Button from "../../common/Button/Button";
import { StakeTab } from "./StakingComponent/StakeTab";
import { UnstakeTab } from "./StakingComponent/UnstakeTab";
import "./StakingPool.scss";
import { useAppSelector } from "../../../app/hooks";
import { getStakingData } from "../../../services/ApiServices/apiService";
import { cryptoDecimals, customizeAddress } from "../../../utils/helpers";
import ConnectWallet from "../../common/Header/ConnectWallet/ConnectWallet";
import { TOKEN_DATA } from "../../../interfaces/Liquidity";
import moment from "moment";
import { Link } from "react-router-dom";
import Shimmer from "../../common/Shimmer/Shimmer";
import SwitchToggle from "../../common/SwitchToggle/SwitchToggle";

const StakingPool = () => {
  const {
    walletAddress,
    chainValues,
  }: { walletAddress: string; chainValues: any } = useAppSelector(
    (store: any) => store?.user
  );
  const [isSearchedTriggered, setIsSearchedTriggered] =
    useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [stakingDetails, setStakingDetails] = useState<any>([]);
  const [showConnectWallet, setConnectWallet] = useState<boolean>(false);
  const [showUserStakeData, setShowUserStakeData] = useState<any>({
    data: {},
    key: 0,
  });
  const [filteredStakingDetails, setFilteredStakingDetails] = useState<any>([]);
  const [expandView, setExpandView] = useState<boolean>(false);
  console.log("🚀 ~ StakingPool ~ setExpandView:", expandView)

  useEffect(() => {
    fetchStakeData();
    // setExpandView(false);
  }, [chainValues, walletAddress]);

  const searching = (key: string) => {
    if (key !== "") {
      const filteredList = JSON.parse(JSON.stringify(stakingDetails));
      const results = filteredList.filter((tokenInfo: any) => {
        return tokenInfo?.tokenName.toLowerCase().includes(key.toLowerCase());
      });
      setFilteredStakingDetails(results);
      setIsSearchedTriggered(true);
      console.log("sakdasjasdjadads", results);
    } else {
      setFilteredStakingDetails([]);
      setIsSearchedTriggered(false);
    }
  };

  const fetchStakeData = async () => {
    setStakingDetails([]);
    const result = await getStakingData(
      chainValues?.symbol == "ETH"
        ? "ETHEREUM"
        : chainValues?.symbol == "BSC"
        ? "BINANCE"
        : "SAITACHAIN",
      walletAddress ?? ""
    );
    const updatedStakingData = await result?.map((item: any, key: any) => {
      if (item?.userData?.length > 0) {
        item.userModalDetails = item?.userData.map((value: any, index: any) => {
          value.finalReward = item?.finalReward[index];
          value.rewardCalculated = item?.rewardCalculated[index];
          value.lockedTill =
            item?.userData?.length > 0
              ? moment
                  .unix(Number(value[1]) + item?.stakePeriod * 60)
                  .format("DD MMM YYYY")
              : null;
          return value;
        });
      }
      return item;
    });
    setStakingDetails(updatedStakingData);
    if (Object.entries(showUserStakeData?.data).length != 0) {
      setShowUserStakeData({
        data: updatedStakingData[showUserStakeData?.key],
        key: showUserStakeData?.key,
      });
    }
  };
  const handleStakeOnly = (toggleVal: boolean) => {
    if (toggleVal) {
      const stakedData = (
        filteredStakingDetails?.length > 0
          ? filteredStakingDetails
          : stakingDetails
      )?.filter((item: any) => item?.userData?.length > 0);
      setFilteredStakingDetails(stakedData);
    } else {
      setFilteredStakingDetails(stakingDetails);
    }
  };
  return (
    <section className="staking_pool">
        <div className="staking_header">
          <SwitchToggle
            label="Staked Only"
            onClick={() => {
              setToggle(!toggle);
              handleStakeOnly(!toggle);
            }}
          />
          <div className="search_input">
            <span>
              <SearchIcon />
            </span>
            <input
              type="text"
              placeholder="Search"
              onChange={(e: any) => searching(e.target.value)}
            />
          </div>
        </div>
        {showUserStakeData?.data && expandView && (
          <Row>
            <Col lg={6}>
              <div className="staking_pool_faq">
                <h2>Staking Pool</h2>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span>Select Stake Token</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>The user Selects the Token they wish to get staked.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span>Enter Stake Amount</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The user enters the amount of token they want to get
                        staked.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span>Approval Check (If Needed)</span>{" "}
                      <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        If the user stake token for the first time, they must
                        approve the contract to lock the tokens
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span>Stake Tokens</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The user confirms the transaction for staking the
                        respective tokens in the contract and the tokens get
                        lock in the contract for the time period of that pool
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <span>Unstake Tokens</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The user can unstake tokens after the time period is
                        over and can earn the rewards generated from staking.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col lg={6}>
              <div className="addCardBox">
                <div className="addCard">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="stake"
                  >
                    <Nav>
                      <Nav.Item>
                        <Nav.Link eventKey="stake">Stake</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="unstake">Unstake</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="stake">
                        <StakeTab
                          tokenData={showUserStakeData?.data}
                          refreshData={fetchStakeData}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="unstake">
                        <UnstakeTab
                          tokenData={showUserStakeData?.data}
                          refreshData={fetchStakeData}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {chainValues.label == "SBC" ? (
          <h1 style={{ textAlign: "center", marginTop: "20px" }}>
            Coming Soon..
          </h1>
        ) : (
          <div className="cardSection">
            <div className="cardSection_inner">
              {(isSearchedTriggered || toggle
                ? filteredStakingDetails
                : stakingDetails
              )?.length > 0 ? (
                <Row>
                  {(isSearchedTriggered || toggle
                    ? filteredStakingDetails
                    : stakingDetails
                  )
                    .slice(0, expandView ? 4 : undefined)
                    .map(
                      (data: any, key: any) => (
                        // data?.isDeleted == true ? (
                        //   ""
                        // ) : (
                        <Col lg={3} md={4} sm={6}>
                          <div className="CardField">
                            <div className="countNo">#{key + 1}</div>
                            <div className="CardField_tophead">
                              <img src={data.tokenLogo} alt="tokenIcon" />
                              <Link
                                target="_blank"
                                to={`${chainValues?.explorerUrl}/address/${data?.stakingAddress}`}
                              >
                                <div className="CardField_tophead_textSec">
                                  <h6>{data.tokenName}</h6>
                                  <p>
                                    {customizeAddress(data.stakingAddress)}{" "}
                                    <ShareIcon />
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <ul className="CardField_list" key={key}>
                              <li>
                                <label>Lock</label>
                                <p>{data?.stakePeriod / 1440} Days</p>
                              </li>
                              <li>
                                <label>
                                  APR <InfoBlackIcon />
                                </label>
                                <p>{data.rewardRate}%</p>
                              </li>
                              <li>
                                <label>Total Staked</label>
                                <p>
                                  {cryptoDecimals(
                                    data?.totalStakedIn / 10 ** 18 || "0"
                                  )}{" "}
                                  {data?.tokenSymbol}
                                </p>
                              </li>
                            </ul>
                            <Button
                              className="btnSize"
                              fluid
                              text={walletAddress ? "Select" : "Connect Wallet"}
                              onClick={() => {
                                if (walletAddress) {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                  setShowUserStakeData({
                                    data: data,
                                    key: key,
                                  });
                                  setExpandView(true);
                                } else setConnectWallet(true);
                              }}
                            />
                          </div>
                        </Col>
                      )
                      // )
                    )}
                  <div className="cardViewBtn">
                    <Button className="btnSize" fluid text="View More" />
                  </div>
                </Row>
              ) : stakingDetails == undefined ||
                (filteredStakingDetails?.length == 0 &&
                  (toggle || isSearchedTriggered)) ? (
                <h1>No record Found</h1>
              ) : (
                <Row className="shimmer_row">
                  {Array.from({ length: 12 })?.map((item) => (
                    <Col lg={3} md={4} sm={6}>
                      <Shimmer height={234} fluid />
                    </Col>
                  ))}
                </Row>
              )}

              {!expandView || stakingDetails?.length <= 4 ? (
                ""
              ) : (
                <div className="cardViewBtn">
                  <Button
                    className="btnSize"
                    fluid
                    text="View More"
                    // onClick={() => setExpandView(false)}
                  />
                </div>
              )}
              <ConnectWallet
                show={showConnectWallet}
                handleClose={() => setConnectWallet(false)}
              />
            </div>
          </div>
        )}
      </section>
  );
};

export default StakingPool;
