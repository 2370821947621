import { useEffect, useState } from "react";
import {
  setTokenList,
  setTokenOne,
  setTokenTwo,
} from "../../../../features/theme/token.slice";
import { TOKEN_DATA } from "../../../../interfaces/Liquidity";
import Button from "../../Button/Button";
import CommonModal from "../CommonModal/CommonModal";
import {
  CheckIcon,
  DownArrowIcon,
  SearchIcon,
} from "../../../../assets/icons/svgicons";
import "./TokensModal.scss";
import { callContractGetMethod } from "../../../../services/contractServices/contractMethods";
import { useWalletConnect } from "../../../../CustomHook/useWalletConnect";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import search from "../../../../assets/icons/Search.svg";

const TokensModal = ({
  tokenActive,
  field,
  readOnly,
  data,
}: {
  tokenActive?: boolean;
  field?: string;
  readOnly?: boolean;
  data?: any;
}) => {
  const dispatch = useAppDispatch();
  const { walletProvider } = useWalletConnect();
  const {
    tokenList,
    tokenOne,
    tokenTwo,
  }: { tokenList: TOKEN_DATA[]; tokenOne: TOKEN_DATA; tokenTwo: TOKEN_DATA } =
    useAppSelector((store: any) => store?.token);

  const [showToken, setShowToken] = useState<boolean>(false);
  const [isSearchedTriggered, setIsSearchedTriggered] =
    useState<boolean>(false);
  const [filteredTokenList, setFilteredTokenList] = useState<TOKEN_DATA[]>([]);

  const handleTokenSelect = async (tokenSelected: TOKEN_DATA) => {
    if (field === "Field1") {
      if (tokenTwo?.name === tokenSelected.name) {
        return;
      }
      dispatch(setTokenOne(tokenSelected));
    } else {
      if (tokenOne?.name === tokenSelected.name) {
        return;
      }
      dispatch(setTokenTwo(tokenSelected));
    }
    setShowToken(false);
    setIsSearchedTriggered(false);
  };

  const handleSearch = async (data: string) => {
    const key = data.toLowerCase();
    if (key !== "") {
      const filteredList: any = tokenList.filter((token: TOKEN_DATA) => {
        return (
          token?.symbol?.toLowerCase().includes(key) ||
          token?.address?.toLowerCase().includes(key) ||
          token?.name?.toLowerCase().includes(key)
        );
      });
      console.log("asudaladad", filteredList);
      if (filteredList?.length == 0 && data.length == 42) {
        try {
          const decimals = await dispatch(
            callContractGetMethod(
              "decimals",
              [],
              "dynamic",
              data,
              walletProvider
            )
          );
          console.log(decimals, "decimals");
          const name = await dispatch(
            callContractGetMethod("name", [], "dynamic", data, walletProvider)
          );
          console.log(name, "name");
          const symbol = await dispatch(
            callContractGetMethod("symbol", [], "dynamic", data, walletProvider)
          );
          console.log(symbol, "symbol");

          if (name && symbol && decimals !== undefined) {
            const newTokenToAdd: any = {
              name,
              symbol,
              decimals,
              isNative: false,
              address: data,
              icon: search,
            };
            console.log("asdikdskadadsadsa", newTokenToAdd);
            dispatch(setTokenList([...tokenList, newTokenToAdd]));
            setFilteredTokenList([newTokenToAdd]);
            setIsSearchedTriggered(true);
          } else {
            setFilteredTokenList([]);
            setIsSearchedTriggered(true);
          }
        } catch (error) {
          console.log(error, "erorrrrrr");
          setFilteredTokenList([]);
          setIsSearchedTriggered(true);
        }
      } else {
        setFilteredTokenList(filteredList);
        setIsSearchedTriggered(true);
      }
    } else {
      setFilteredTokenList([]);
      setIsSearchedTriggered(false);
    }
  };
  // console.log(
  //   "searcehas",
  //   filteredTokenList?.length,
  //   isSearchedTriggered,
  //   tokenList?.length > 0
  // );
  return (
    <>
      {!tokenActive ? (
        <Button className="tokenBtn " onClick={() => setShowToken(true)}>
          <img src={readOnly ? data?.tokenLogo : tokenOne.icon} alt="" />
          <span className="tokenIcon">
            {readOnly ? data?.tokenName : tokenOne?.name}
          </span>
          {!readOnly ? <DownArrowIcon /> : null}
        </Button>
      ) : (
        <Button className="tokenBtn " onClick={() => setShowToken(true)}>
          <img src={tokenTwo.icon} alt="" />
          <span className="tokenIcon">{tokenTwo?.name}</span>
          <DownArrowIcon />
        </Button>
      )}
      {!readOnly ? (
        <CommonModal
          className="tokens_modal_custom"
          show={showToken}
          handleClose={() => {
            setIsSearchedTriggered(false);
            setShowToken(false);
          }}
          // heading="Select Token"
          heading="Select Token"
          // hideCloseButton
        >
          <div className="modal_input">
            <div className="search_icon">
              <SearchIcon />
            </div>
            <input
              placeholder="Search"
              onChange={(e: any) => handleSearch(e.target.value)}
            ></input>
          </div>
          <div className="modal_coins">
            {/* <h3>Select Network : Ethereum</h3> */}
            {tokenList?.slice(0, 3).map((token: any, index: any) => (
              <li className="modal_coins_in" key={index}>
                <button onClick={() => handleTokenSelect(token)}>
                  <span>
                    <img src={token.icon} alt="" />
                  </span>
                  {token.name}
                </button>
              </li>
            ))}
          </div>
          <ul>
            {(isSearchedTriggered ? filteredTokenList : tokenList)?.length >
            0 ? (
              (isSearchedTriggered ? filteredTokenList : tokenList).map(
                (value: any) => (
                  <li key={value?.name}>
                    <button
                      className={`${
                        (field === "Field1" &&
                          tokenTwo?.name === value?.name) ||
                        (field === "Field2" && tokenOne?.name === value?.name)
                          ? "active"
                          : "in-active"
                      }`}
                      onClick={() => handleTokenSelect(value)}
                      disabled={
                        field === "Field1"
                          ? tokenTwo?.name === value?.name
                          : tokenOne?.name === value?.name
                      }
                    >
                      <span>
                        <img src={value?.icon} alt="" />
                      </span>
                      {value?.name}
                      <span className="checkbtn">
                        <CheckIcon />
                      </span>
                    </button>
                  </li>
                )
              )
            ) : (
              <p className="no_token_found">No token found.</p>
            )}
          </ul>
        </CommonModal>
      ) : null}
    </>
  );
};

export default TokensModal;
