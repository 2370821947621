import { Link, NavLink } from "react-router-dom";
import "./Sidebar.scss";
// import { BridgeIcon, FarmIcon, LiquidityIcon, MenuIcon, PoolIcon, SwapIcon, TradeIcon } from '../../../assets/icons/svgicons'
import logo from "../../../assets/logo/logo.svg";
import lightLogo from "../../../assets/logo/light-logo.svg";
import { useAppSelector } from "../../../app/hooks";
import { ROUTES } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { toast } from "../Toasts/Toast";

type propTypes = {
  active?: boolean;
  handleActive?: any;
  setRouteDetail?: any;
};

const Sidebar = (props: propTypes) => {
  const { walletAddress } = useAppSelector((state: any) => state?.user);
  const [activeTab, setActiveTab] = useState(window.location.pathname);

  useEffect(() => {
    // if (!walletAddress) toast?.info("Please connect your wallet first!");
  }, [activeTab]);

  const { theme } = useAppSelector((state) => state.theme);
  const navs = [
    {
      // icon: <SwapIcon />,
      label: "Swap",
      to: ROUTES.SWAP,
    },
    {
      // icon: <LiquidityIcon />,
      label: "Liquidity",
      to: ROUTES.LIQUIDITY,
    },
    {
      // icon: <TradeIcon />,
      label: "Trade",
      to: ROUTES.TRADE,
    },
    {
      // icon: <PoolIcon />,
      label: "Staking Pool",
      to: ROUTES.STAKING_POOL,
    },
    {
      label: "Cross Chain",
      to: ROUTES.CHAIN_CROSS,
    },
    {
      // icon: <FarmIcon />,
      label: "Farm",
      to: ROUTES.FARM,
    },
    {
      // icon: <BridgeIcon />,
      label: "XBridge",
      to: ROUTES.XBRIDGE,
    },
  ];
  console.log("habcaaa", props?.active);
  return (
    <>
      <div
        onClick={props.handleActive}
        className={`${props.active ? "active" : ""} sidebar_overlay d-xl-none`}
      />
      <aside className={`sidebar ${props.active ? "active" : ""}`}>
        <div className={"sidebar_head"}>
          <img
            src={theme === "light" ? lightLogo : logo}
            alt="saitaswap logo"
          />
        </div>
        <ul>
          {navs?.map((item) => {
            return (
              <li key={item.label}>
                <Link
                  onClick={() => {
                    props?.setRouteDetail(item?.to);
                    props?.handleActive();
                    setActiveTab(item?.to);
                  }}
                  target={item?.label!="XBridge"?"":"_blank"}
                  to={item?.label!="XBridge"?item.to:"https://xbridge.tech/"}
                  className={activeTab == item?.to ? "active" : ""}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
