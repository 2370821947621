import { useEffect, useState } from "react";
import { store } from "../app/store";
import { useWalletConnect } from "./useWalletConnect";
import {
  getNativeBalance,
  getTokenBalance,
} from "../services/contractServices/contractCallServices";

const useStakeTokenBalance = ({
  dispatch,
  tokenDetails,
}: {
  dispatch: any;
  tokenDetails: any;
}) => {
  const { walletAddress }: { walletAddress: string } = store?.getState()?.user;
  const {
    walletProvider,
    chainId,
  }: {
    walletProvider: any;
    chainId: number | undefined;
  } = useWalletConnect();
  const [tokenBalance, setTokenBalance] = useState({
    token1Balance: 0,
    token1BalanceConverted: 0,
  });
  const fetchData = async () => {
    console.log(tokenDetails,"tokenDetails");
    const { isTokenOneNative, tokenOneData } = tokenDetails;
    if (walletAddress) {
      let res1: any;
      if (isTokenOneNative) {
        res1 = await getNativeBalance(walletAddress, walletProvider);
      } else {
        console.log("ksikhnajad", tokenOneData);
        const newTokenData = {
          address: tokenOneData?.tokenAddress,
          decimals: tokenOneData?.tokenDecimal,
        };
        res1 = await getTokenBalance({
          tokenData: newTokenData,
          dispatch,
          walletAddress: walletAddress,
          walletProvider,
        });
      }
      console.log("asdiasjdsadassdhufr", res1);
      setTokenBalance({
        token1Balance: res1?.res || "0",
        token1BalanceConverted: res1?.calculatedBalance,
      });
    }
  };

  useEffect(() => {
    if (walletAddress && tokenDetails && chainId) {
      fetchData();
    }
  }, [walletAddress, tokenDetails, chainId]);

  return { tokenBalance, fetchData };
};

export default useStakeTokenBalance;
