import { store } from "../../app/store";
import { STAKING } from "../../interfaces/staking";
import { nativeTokenAddress } from "../../utils/constants";
import { getAllowanceAndApprovalHelper } from "../LiquidityServices/AddLiquidityHelper";
import { callContractSendMethod } from "../contractServices/contractMethods";

export const executeStake = async (data: STAKING) => {
  try {
    const {
      stakeToken,
      stakeAmount,
      stakeType,
      dispatch,
      walletAddress,
      walletProvider,
      platformFee,
      spender,
      setModalData,
    } = data;
    console.log(data, "data------------------->");
    const list = store.getState()?.user?.contractDetails;
    // console.log("sak,jdasdad", data);
    let response;
    if (stakeToken !== nativeTokenAddress) {
      setModalData({
        title: "Approval",
        bodyText: `Please confirm approval for staking`,
        status: "in-progress",
        txHash: null,
      });
      response = await getAllowanceAndApprovalHelper({
        customToken: stakeToken,
        dispatch,
        tokenOneAddress: 0,
        tokenTwoAddress: 0,
        walletAddress,
        inputOne: stakeAmount,
        walletProvider,
        spender,
      });
    }


    if (response) {
      if (stakeToken !== nativeTokenAddress) {
        setModalData({
          title: "Approval",
          bodyText: `Approval success`,
          status: "success",
          txHash: null,
        });
      }
      setModalData({
        title: "Staking",
        bodyText: `Please confirm transaction for Staking Token`,
        status: "in-progress",
        txHash: null,
      });
      const res = await dispatch(
        callContractSendMethod(
          "deposit",
          [stakeToken, stakeAmount?.convertedValue, stakeType],
          walletAddress,
          "staking",
          stakeToken === nativeTokenAddress ? (platformFee + stakeAmount?.convertedValue)?.toString : platformFee,
          list?.stakingFactory?.address,
          walletProvider
        )
      );
      // console.log("soudanadaidadasda", res?.code);
      if (
        res?.code != 4001 &&
        res?.code != -32603 &&
        res?.code != 5000 &&
        res?.transactionHash
      ) {
        setModalData({
          title: "Staking",
          bodyText: `Transaction successful for Staking Token`,
          status: "success",
          txHash: res?.transactionHash,
        });
      } else if (
        res?.code == undefined ||
        res?.code == 4001 ||
        res?.code == -32603 ||
        res?.code == 5000
      ) {
        setModalData({
          title: "Staking",
          bodyText: res?.message.split("{")[0]
            ? res?.message.split("{")[0]
            : res?.message.split(":")[0],
          status: "failed",
          txHash: null,
        });
      }
      return res;
    } else {
      setModalData({
        title: "Approval",
        bodyText: `Approval Failed`,
        status: "failed",
        txHash: null,
      });
    }
  } catch (error) {
    // toast.error(error?.message);
    // errorHelperContract(error, "call", "getTokenApproval");
    return 0;
  }
};

export const executeUnStake = async (data: any) => {
  try {
    const {
      stakeToken,
      unstakeAmount,
      stakeType,
      dispatch,
      walletAddress,
      walletProvider,
      platformFee,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    setModalData({
      title: "Unstaking",
      bodyText: `Please confirm transaction for Unstaking Token`,
      status: "in-progress",
      txHash: null,
    });
    const res = await dispatch(
      callContractSendMethod(
        "withdraw",
        [stakeToken, unstakeAmount, stakeType],
        walletAddress,
        "staking",
        platformFee,
        list?.stakingFactory?.address,
        walletProvider
      )
    );
    // console.log("soudanadaidadasda", res);
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Unstaking",
        bodyText: `Transaction successful for Unstaking Token`,
        status: "success",
        txHash: res?.transactionHash,
      });
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
    }
    return res;
  } catch (error) {
    // toast.error(error?.message);
    // errorHelperContract(error, "call", "getTokenApproval");
    return 0;
  }
};

export const enablePool = async (data: any) => {
  try {
    const {
      approveLpToken,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
      // setModalData,
    } = data;
    // console.log("sak,jdasdad", data);
    // setModalData({
    //   title: "Approval",
    //   bodyText: `Please confirm approval for staking`,
    //   status: "in-progress",
    //   txHash: null,
    // });
    const response: any = await getAllowanceAndApprovalHelper({
      customToken: approveLpToken,
      dispatch,
      tokenOneAddress: 0,
      tokenTwoAddress: 0,
      walletAddress,
      inputOne: { inputValue: 0 },
      walletProvider,
      spender: proxyAddress,
    });
    // console.log("ResponseFromApproval", response);
    return response;
    // setModalData({
    //   title: "Approval",
    //   bodyText: `Approval success`,
    //   status: "success",
    //   txHash: response?.transactionHash,
    // });
    // setModalData({
    //   title: "Staking",
    //   bodyText: `Please confirm transaction for staking`,
    //   status: "in-progress",
    //   txHash: null,
    // });
    // const res = await dispatch(
    //   callContractSendMethod(
    //     "deposit",
    //     [stakeToken, stakeAmount?.convertedValue, stakeType],
    //     walletAddress,
    //     "staking",
    //     platformFee,
    //     list?.stakingFactory?.address,
    //     walletProvider
    //   )
    // );
    //   console.log("soudanadaidadasda", res);
    //   if (res?.code != 4001 && res?.code != -32603) {
    //     setModalData({
    //       title: "Staking",
    //       bodyText: `Transaction successful for staking`,
    //       status: "success",
    //       txHash: res?.transactionHash,
    //     });
    //   } else {
    //     setModalData({
    //       title: "Staking",
    //       bodyText: res?.message.split(":")[1]
    //         ? res?.message.split(":")[1]
    //         : res?.message.split(":")[0],
    //       status: "failed",
    //       txHash: null,
    //     });
    //   }
    //   return res;
    // } else {
    //   setModalData({
    //     title: "Approval",
    //     bodyText: `Approval Failed for`,
    //     status: "failed",
    //     txHash: null,
    //   });
    // }
  } catch (error) {
    // toast.error(error?.message);
    // errorHelperContract(error, "call", "getTokenApproval");
    return 0;
  }
};

export const userDeposit = async (data: any) => {
  try {
    const {
      tokenAddress,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
      fee,
      stakeType,
      inputValue,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    // console.log("sajdasdad", data, list?.stakingFactory?.address);
    setModalData({
      title: "Farming",
      bodyText: `Please confirm transaction for Depositing LP Tokens`,
      status: "in-progress",
      txHash: null,
    });
    const res = await dispatch(
      callContractSendMethod(
        "deposit",
        [tokenAddress, stakeType, inputValue],
        walletAddress,
        "farm",
        fee,
        list?.farm?.address,
        walletProvider
      )
    );
    // console.log("sygshhshshshshsaas", res?.message.split("{"));
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: `Transaction successful for Depositing`,
        status: "success",
        txHash: res?.transactionHash,
      });
      return true;
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
      return false;
    }
  } catch (error) {
    console.log("trrddeddd", error);
    // toast.error(error?.message);
    // errorHelperContract(error, "call", "getTokenApproval");
    return 0;
  }
};

export const userWithdraw = async (data: any) => {
  try {
    const {
      tokenAddress,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
      fee,
      stakeType,
      inputValue,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    // console.log("sajdasdad", data, list?.stakingFactory?.address);
    setModalData({
      title: "Farming",
      bodyText: `Please confirm transaction for Withdrawing Tokens`,
      status: "in-progress",
      txHash: null,
    });
    const res = await dispatch(
      callContractSendMethod(
        "withdraw",
        [tokenAddress, stakeType, inputValue],
        walletAddress,
        "farm",
        fee,
        list?.farm?.address,
        walletProvider
      )
    );
    // console.log("soudanadaidadasda", res);
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: `Transaction successful for Withdrawing`,
        status: "success",
        txHash: res?.transactionHash,
      });
      return true;
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
      return false;
    }
  } catch (error) {
    // toast.error(error?.message);
    // errorHelperContract(error, "call", "getTokenApproval");
    return 0;
  }
};

export const executeHarvesting = async (data: any) => {
  try {
    const {
      tokenAddress,
      proxyAddress,
      dispatch,
      walletAddress,
      walletProvider,
      fee,
      stakeType,
      setModalData,
    } = data;
    const list = store.getState()?.user?.contractDetails;
    // console.log("sajdasdad", data, list?.stakingFactory?.address);
    setModalData({
      title: "Farming",
      bodyText: `Please confirm transaction for Harvesting`,
      status: "in-progress",
      txHash: null,
    });
    const res = await dispatch(
      callContractSendMethod(
        "harvest",
        [tokenAddress, stakeType],
        walletAddress,
        "farm",
        fee,
        list?.farm?.address,
        walletProvider
      )
    );
    if (
      res?.code != 4001 &&
      res?.code != -32603 &&
      res?.transactionHash &&
      res?.code != 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: `Transaction successful for Harvesting`,
        status: "success",
        txHash: res?.transactionHash,
      });
      return true;
    } else if (
      res?.code == undefined ||
      res?.code == 4001 ||
      res?.code == -32603 ||
      res?.code == 5000
    ) {
      setModalData({
        title: "Farming",
        bodyText: res?.message.split("{")[0]
          ? res?.message.split("{")[0]
          : res?.message.split(":")[0],
        status: "failed",
        txHash: null,
      });
      return false;
    }
  } catch (error) {
    // toast.error(error?.message);
    // errorHelperContract(error, "call", "getTokenApproval");
    return 0;
  }
};
