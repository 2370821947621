import { Outlet } from "react-router-dom";
import swap_img from "../../../assets/images/1000_swap_icon.png";
import HeadCard from "../../common/HeadCard/HeadCard";
import "./Swap.scss";
import { useState } from "react";
import TxnModal from "../../common/Modals/TxnModal/TxnModal";
const Swap = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  return (
    <>
      <section className="swapPage">
        {/* <HeadCard
          title="1,000 SWAP to all registered customers!"
          text="Make an account, complete the trade, and get a nice bonus. To buy a BTC cashback subscription, collect SWAP."
          image={swap_img}
          imageAnimate
        /> */}
        <Outlet />
      </section>
      {/* <button onClick={() => {
                setShow(true);
                setData({
                    status: "success",
                    title: "Swap Submitted"
                })
            }}>Success</button>
            <button onClick={() => {
                setShow(true);
                setData({
                    status: "failed",
                    title: "Swap Failed"
                })
            }}>Failed</button>
            <button onClick={() => {
                setShow(true);
                setData({
                    status: "in-progress",
                    title: "Swap In Progress"
                })
            }}>In Progress</button>
            <TxnModal
                show={show}
                handleClose={() => setShow(false)}
                data={data}
            /> */}
    </>
  );
};

export default Swap;
