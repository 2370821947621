import { useEffect, useMemo, useState } from "react";
import { InfoBlackIcon } from "../../../../assets/icons/svgicons";
import Button from "../../../common/Button/Button";
import Input from "../../../common/Input/Input";
import Commonselect from "../../../common/Select/Commonselect";
import TokenselectModal from "../../../common/TokenselectModal/TokenselectModal";
import "./IndexStaking.scss";
import {
  TradeData,
  getStakingData,
} from "../../../../services/ApiServices/apiService";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import useFetchTokenBalance from "../../../../CustomHook/useFetchTokenBalance";
import {
  BALANCE_HOOK,
  DOLLAR_VAL,
  INPUTS,
  MODAL_STATE,
  TOKEN_DETAILS,
} from "../../../../interfaces/common";
import { TOKEN_DATA } from "../../../../interfaces/Liquidity";
import { store } from "../../../../app/store";
import useStakeTokenBalance from "../../../../CustomHook/useStakeTokenBalance";
import {
  cryptoDecimals,
  toFixed,
  validateInputField,
} from "../../../../utils/helpers";
import { callContractSendMethod } from "../../../../services/contractServices/contractMethods";
import { executeStake } from "../../../../services/stakingServices/stakingHelper";
import { daysToValueMap } from "../../../../utils/constants";
import { useWalletConnect } from "../../../../CustomHook/useWalletConnect";
import useIsWrongNetwork from "../../../../CustomHook/useisWrongNetwork";
import TxnModal from "../../../common/Modals/TxnModal/TxnModal";
import CommonTable from "../../../common/CommonTable/CommonTable";
import UserStakingInfo from "../../../common/Modals/UserStakingInfo/UserStakingInfo";

const periodOption = [
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "month", label: "Month" },
];
export const StakeTab = ({
  tokenData,
  refreshData,
}: {
  tokenData: any;
  refreshData: any;
}) => {
  const dispatch = useAppDispatch();
  const { walletProvider } = useWalletConnect();
  const isWrongNetwork: boolean = useIsWrongNetwork();
  const { walletAddress }: { walletAddress: string } = useAppSelector(
    (store: any) => store?.user
  );

  const [percentage, setpercentage] = useState<number | null>(null);
  const [stakinInfo, setStakingInfo] = useState<any>({
    originalData: [],
    stakeData: [],
  });

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [tk1DollarValue, setTk1DollarValue] = useState<number | string>(0.0);
  const [stakeInput, setStakeInput] = useState<INPUTS>({
    inputValue: "",
    convertedValue: "",
  });
  const [show, setShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<MODAL_STATE>({
    status: "",
    bodyText: "",
    title: "",
    txHash: "",
  });
  console.log("dsakjdsalaoalidd", tokenData);
  const tokenDetails: TOKEN_DETAILS = useMemo(() => {
    const list: TOKEN_DATA[] = store.getState()?.token?.tokenList;
    return {
      tokenOneAddress: tokenData?.tokenAddress,
      tokenOneData: tokenData,
      isTokenOneNative:
        list[0]?.address == tokenData?.tokenAddress ? true : false,
    };
  }, [tokenData]);

  const { tokenBalance, fetchData }: any = useStakeTokenBalance({
    dispatch,
    tokenDetails,
  });

  useEffect(() => {
    emptyValues();
    trade();
    deleteUnusedData();
  }, [tokenData]);

  const deleteUnusedData = () => {
    const filteredData = tokenData?.userModalDetails?.filter((item: any) => {
      if (Number(item[0]) > 0) {
        return item;
      }
    });
    console.log("deleeeeee", filteredData);
    setStakingInfo({ originalData: tokenData, stakeData: filteredData });
  };

  const emptyValues = async () => {
    setStakeInput({
      convertedValue: "",
      inputValue: "",
    });
    setpercentage(null);
  };

  const trade = async () => {
    let getDollarPriceValue: DOLLAR_VAL = await TradeData(
      tokenData.tokenSymbol
    );

    if (
      !getDollarPriceValue?.token0 ||
      getDollarPriceValue?.token0 === "This token is not Listed on CMC."
    ) {
      setTk1DollarValue(0.0);
    } else {
      setTk1DollarValue(getDollarPriceValue?.token0);
    }
  };

  const tableheading = [
    { label: "No" },
    { label: "Token Staked" },
    { label: "Current Earnings" },
    { label: "Final Earnings" },
    { label: "Timer" },
    { label: "Status" },
  ];

  const handleInput = async (e: string, max: boolean) => {
    console.log("jaskhkas");
    setpercentage(null);
    const response: boolean | string = await validateInputField(
      e,
      tokenData?.tokenDecimal,
      max,
      emptyValues
    );
    if (response) {
      let convertedValue: string = (
        max ? response : Number(response) * 10 ** tokenData?.tokenDecimal
      ).toLocaleString("fullwide", {
        useGrouping: !1,
      });

      let originalValue: string = (
        max
          ? cryptoDecimals(Number(response) / 10 ** tokenData?.tokenDecimal)
          : response
      ).toLocaleString("fullwide", {
        useGrouping: !1,
      });

      setStakeInput({
        convertedValue: convertedValue,
        inputValue: originalValue,
      });
    }
  };
  ///2079c7
  console.log("kasijkasada", tokenBalance);
  const handlePercClick = (percentage: number) => {
    setpercentage(percentage);
    if (Number(tokenBalance?.token1Balance) > 0) {
      setStakeInput({
        inputValue: (
          (percentage / 100) *
          tokenBalance?.token1BalanceConverted
        ).toString(),
        convertedValue: toFixed(
          ((percentage / 100) * tokenBalance?.token1Balance).toString()
        ),
      });
      // setStakeInput;
    }
  };

  const stakeAsset = async () => {
    const stakeRes = await executeStake({
      stakeToken: tokenData?.tokenAddress,
      stakeAmount: stakeInput,
      platformFee: tokenData?.platformFee,
      stakeType: tokenData.stakingtype,
      dispatch,
      spender: tokenData?.stakingAddress,
      walletAddress,
      walletProvider,
      setModalData,
    });
    if (stakeRes) {
      await fetchData();
      await refreshData();
    }
  };
  const insufficientBalance =
    Number(tokenBalance?.token1Balance) < Number(stakeInput?.convertedValue);
  return (
    <>
      <section className="stakingData">
        <div className="activeCardtoken">
          <ul className="listToken">
            <li>
              <h5>Staking Amount</h5>
              <h6>
                <span>Balance :</span>{" "}
                {tokenBalance?.token1BalanceConverted ?? 0}
              </h6>
            </li>
            <li>
              <div className="inputwithBalance">
                <Input
                  placeholder="0"
                  className="without_bg inputActive"
                  type="number"
                  value={stakeInput?.inputValue}
                  onChange={(e: any) => handleInput(e.target.value, false)}
                  disabled={isWrongNetwork || !walletAddress}
                />
                <h6>
                  ($
                  {cryptoDecimals(
                    Number(tk1DollarValue) * Number(stakeInput?.inputValue) || 0
                  )}
                  )
                </h6>
              </div>
              <div className="listRight">
                <Button
                  text="MAX"
                  className="maxBtn without_bg_border"
                  onClick={() =>
                    Number(tokenBalance?.token1Balance) > 0
                      ? handleInput(tokenBalance?.token1Balance, true)
                      : {}
                  }
                />
                {/* <span className="tokenDetails">
                  <img src={tokenData?.tokenData?.userIcon} alt="" />
                  {tokenData?.tokenData?.tokenSymbol}
                </span> */}
                <TokenselectModal field="" readOnly={true} data={tokenData} setTokenOneChainId={""} />
              </div>
            </li>
          </ul>
        </div>
        <ul className="listPerc">
          <li className={percentage == 25 ? "active" : ""}>
            <span onClick={() => handlePercClick(25)}>25%</span>
          </li>
          <li className={percentage == 50 ? "active" : ""}>
            <span onClick={() => handlePercClick(50)}>50%</span>
          </li>
          <li className={percentage == 75 ? "active" : ""}>
            <span onClick={() => handlePercClick(75)}>75%</span>
          </li>
          <li className={percentage == 100 ? "active" : ""}>
            <span onClick={() => handlePercClick(100)}>100%</span>
          </li>
        </ul>
        <div className="periodStaking">
          <h4>Staking Period</h4>
          <h4>{tokenData?.stakePeriod / 1440 + " " + "Days"}</h4>
          {/* <Commonselect
            // options={periodOption}
            placeholder={tokenData?.stakePeriod / 1440 + " " + "Days"}
            className="selectStaking"
              // defaultValue={tokenData?.stakePeriod / 1440}
          // isSearchable
            // defaultValue={defaultValue}
            // onChange={onChange}
          /> */}
        </div>
        <div className="rewardStaking">
          <h6>Estimated Stake Rewards(s)</h6>
          <ul className="rewardList">
            <li>
              <label>Lock Amount</label>
              {/* <p>
                {cryptoDecimals(
                  tokenData?.finalReward[0] / 10 ** tokenData?.tokenDecimal
                ) ?? 0}{" "}
                {tokenData?.tokenSymbol}
              </p> */}
              <p>
                {cryptoDecimals(
                  tokenData?.amount?.lockAmount / 10 ** tokenData?.tokenDecimal
                ) || 0}
              </p>
            </li>
            {/* <li>
              <label>Locked till</label>
              <p>{tokenData?.lockedTill ?? "Not Staked"}</p>
            </li> */}
            <li>
              <label>Total Reward</label>
              <p>
                {cryptoDecimals(
                  tokenData?.finalReward?.reduce(
                    (acc: any, currentVal: any) =>
                      Number(acc) + Number(currentVal),
                    0
                  ) /
                    10 ** tokenData?.tokenDecimal
                ) || 0}
              </p>
            </li>
            {/* <li>
              <label>
                Network Fee <InfoBlackIcon />
              </label>
              <p>
                <span>Market</span> ~$7.326598
              </p>
            </li> */}
            {/* <li>
              <label>Locked Till</label>
              <p>
                <span>{tokenData?.lockedTill ?? "Not Staked"}</span>
              </p>
            </li> */}
          </ul>
        </div>
        <Button
          className={`btnSize  ${
            !walletAddress
              ? "fluid"
              : insufficientBalance || Number(stakeInput?.inputValue) == 0
              ? "grayBorder"
              : "fluid"
          }`}
          fluid
          disabled={
            !walletAddress
              ? false
              : insufficientBalance || Number(stakeInput?.inputValue) == 0
          }
          text={
            !walletAddress
              ? "Connect Wallet"
              : insufficientBalance
              ? `Insufficient ${tokenData?.tokenSymbol}`
              : "Stake Asset"
          }
          onClick={() => {
            setShow(true);
            stakeAsset();
          }}
        />
      </section>
      <UserStakingInfo
        show={showDetails}
        handleClose={() => setShowDetails(false)}
        stakingInfo={stakinInfo}
      />
      <Button
        text="View Staking Info "
        onClick={() =>
          showDetails ? setShowDetails(false) : setShowDetails(true)
        }
        disabled={tokenData?.userData?.length == 0}
        className="grayBorder btnSize"
        fluid
      />
      <TxnModal
        show={show}
        handleClose={() => setShow(false)}
        data={modalData}
      />
    </>
  );
};
