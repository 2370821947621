import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setChainValues,
  setContractDetails,
  setUserConnected,
  setWalletAddress,
} from "../features/theme/user.slice";
import { NETWORKS, networkConfig } from "../utils/constants";

import {
  setTokenList,
} from "../features/theme/token.slice";
import { NetworkTypes } from "../interfaces/common";

export const useWalletConnect = () => {
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  let { walletProviderType }: any = useWeb3ModalProvider();
  const walletAddress = useSelector((state: any) => state?.user?.walletAddress);
  const isConnected = useSelector((state: any) => state?.user?.userConnected);
  const selectedChain: NetworkTypes = useSelector(
    (state: any) => state?.user?.chainValues
  );

  const connect = async () => {
    try {
      if (window.ethereum) {
        await (window as any).ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(function (accounts: any) {
            dispatch(setWalletAddress(accounts[0]));
            dispatch(setUserConnected(true));
          })
          .catch(function (error: any) {
            console.error('User denied account access:', error);
          });
      } else {
        
      }
    } catch (err) {
      console.warn('failed to connect..', err);
    }
  };


  const switchNetwork = async (chainIdProvided: any) => {
    try {
      let { network }: any = networkConfig(chainIdProvided);
      if (network) {
        await new Promise<any>(async (resolve, reject) => {
          if (!window?.ethereum) return;
          (window as any)?.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: network.chainIdHex }],
          })
            .then((res: any) => {
              setNetworkInReduxState(chainIdProvided);
              resolve(res);
            })
            .catch(async (err: any) => {
              console.log({ err });
              if (err.code == 4001) {
                return;
              }
              if (err.code === 4902) {
                await addNewNetwork(network);
              }
              reject(err);
            });
        });
        setNetworkInReduxState(chainIdProvided);
        return true;
      }
    } catch (error) {
      console.error(error);
      // throw error;
    }
  };

  const addNewNetwork = async (network: any) => {
    if (!window?.ethereum) return;
    await (window as any)?.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: network.chainIdHex,
          chainName: network.name,
          nativeCurrency: {
            name: network.name,
            symbol: network.symbol,
            decimals: network.decimals,
          },
          rpcUrls: [network.rpcUrl],
          blockExplorerUrls: [network.explorerUrl],
        },
      ],
    });
  };

  const setNetworkInReduxState = (chainId: any) => {
    console.log("🚀 ~ setNetworkInReduxState ~ chainId:", chainId)
    const netConfig: any = networkConfig(chainId);
    dispatch(setTokenList(netConfig.tokenList));
    dispatch(setChainValues(netConfig.network));
    dispatch(setContractDetails(netConfig.contractList));
  };

  useEffect(() => {
    let inter: any;
    inter = setTimeout(() => {
      dispatch(setWalletAddress(walletAddress));
      if (walletAddress) {
        dispatch(setUserConnected(true));
      } else {
        dispatch(setUserConnected(false));
      }
    }, 1000);
    return () => clearInterval(inter);
  }, [walletAddress]);

  useEffect(() => {
    connect()
  }, [])

  // useEffect(() => {
  //   let inter: any;
  //   inter = setTimeout(() => {
  //     if (selectedChain.chainId) {
  //       const network = NETWORKS.find((network) => {
  //         return network.chainId === selectedChain.chainId
  //       })

  //       if (network) {
  //         setNetworkInReduxState(selectedChain.chainId);
  //       }
  //     }
  //   }, 1000);

  //   return () => clearTimeout(inter);
  // }, [selectedChain]);

  return {
    open,
    disconnect,
    address: walletAddress,
    chainId: selectedChain.chainId,
    isConnected: isConnected,
    walletProvider: window?.ethereum,
    walletProviderType,
    // handleDisconnect,
    switchNetwork,
    setNetworkInReduxState,
  };
};
