import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  CheckIcon,
  CurrencyEthereum,
  MoonIcon,
  SunIcon,
} from '../../../assets/icons/svgicons';
import lightLogo from '../../../assets/logo/light-logo.svg';
import logo from '../../../assets/logo/logo.svg';
import smallLogo from '../../../assets/logo/small-logo.svg';
import { useWalletConnect } from '../../../CustomHook/useWalletConnect';
import { setTheme } from '../../../features/theme/theme.slice';
import { ROUTES, NETWORKS } from '../../../utils/constants';
import { customizeAddress } from '../../../utils/helpers';
import Button from '../Button/Button';
import Sidebar from '../Sidebar/Sidebar';
import ConnectWallet from './ConnectWallet/ConnectWallet';
import './Header.scss';
import { NetworkTypes } from '../../../interfaces/common';
import useIsWrongNetwork from '../../../CustomHook/useisWrongNetwork';
import { setImportedLps } from '../../../features/theme/user.slice';
import {
  nativeData,
  networkChainName,
  networkList,
} from '../../../utils/OkxHelpers';
import { toast } from '../Toasts/Toast';

type propTypes = {
  active?: boolean;
  handleActive?: () => void;
};

const Header = (props: propTypes) => {
  const { theme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const { switchNetwork, setNetworkInReduxState } = useWalletConnect();
  const { selectedTo, selectedFrom, allTokenList } = useAppSelector<any>(
    (state) => state.okx
  );
  const [searchParams, setSearchParams] = useSearchParams();
  console.log('🚀 ~ Header ~ searchParams:', searchParams);
  const walletAddress = useSelector((state: any) => state?.user?.walletAddress);
  const selectedChain: NetworkTypes = useSelector(
    (state: any) => state?.user?.chainValues
  );
  const [show, setShow] = useState(false);
  const [isNetworkSwitched, setIsSwitchedNetwork] = useState(false);
  const [routeDetail, setRouteDetail] = useState<string>(
    window?.location?.pathname
  );
  const handleChange = () => {
    dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'));
  };

  useEffect(() => {
    handleSwitchNetwork({
      chainId:
        searchParams.get('chainId') === 'eth'
          ? 1
          : searchParams.get('chainId') === 'bsc'
          ? 56
          : 1209,
    });

    dispatch(setImportedLps([]));
    setIsSwitchedNetwork(false);
  }, [walletAddress]);

  const handleSwitchNetwork = async (e: any) => {
    try {
      switchNetwork(e.chainId);
      setNetworkInReduxState(e.chainId);
    } catch (error: any) {
      return;
    }
  };
  const getImageFunction = (name: string) => {
    console.log('namegetImageFunction', name);
    const result = networkList?.find((data: any) => data?.name == name);
    return name == 'Conflux Espace' && !result
      ? 'https://static.okx.com/cdn/wallet/logo/cfx_19200.png'
      : !result
      ? 'https://static.okx.com/cdn/wallet/logo/BNB-20220308.png'
      : result?.icon;
  };
  return (
    <>
      <header className='header'>
        <Container fluid className='px-40'>
          <div className='header_in'>
            <Link to={ROUTES.SWAP} className={'header_logo'}>
              <img
                className='d-sm-block d-none'
                src={theme === 'light' ? lightLogo : logo}
                alt='logo'
              />
              <img src={smallLogo} alt='logo' className='d-sm-none' />
            </Link>
            <Sidebar
              handleActive={props.handleActive}
              active={props.active}
              setRouteDetail={setRouteDetail}
            />
            <div className='header_action'>
              {true && (
                <Select
                  isDisabled={true}
                  options={NETWORKS}
                  defaultValue={selectedChain || NETWORKS[0]}
                  value={selectedChain}
                  classNamePrefix={'select'}
                  isSearchable={false}
                  placeholder={<CurrencyEthereum />}
                  onChange={(e) => {
                    if (!walletAddress) {
                      // toast.info("Please connect your wallet first!");
                    }
                    handleSwitchNetwork(e);
                  }}
                  className='header_select'
                  formatOptionLabel={(options: NetworkTypes) => {
                    return (
                      <>
                        <img
                          src={
                            routeDetail == '/cross-chain'
                              ? getImageFunction(
                                  networkChainName[selectedFrom?.chainID]
                                )
                              : options?.icon
                          }
                          alt={options.label}
                        />
                        <span>{options.label}</span>
                        {options?.chainId == selectedChain?.chainId ? (
                          <CheckIcon />
                        ) : (
                          ''
                        )}
                      </>
                    );
                  }}
                />
              )}

              <div className='connect_btn'>
                <Button onClick={() => setShow(true)} disabled>
                  {walletAddress ? customizeAddress(walletAddress) : 'Connect'}
                </Button>
              </div>
              <button
                className={`theme_btn ${theme === 'dark' ? 'active' : ''}`}
                onClick={handleChange}
              >
                <motion.div layout>
                  <AnimatePresence mode='popLayout'>
                    {theme === 'dark' ? (
                      <motion.div
                        key={1}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <MoonIcon />
                      </motion.div>
                    ) : (
                      <motion.div
                        key={2}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <SunIcon />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              </button>
            </div>
          </div>
        </Container>
      </header>
      <ConnectWallet show={show} handleClose={() => setShow(false)} />
    </>
  );
};

export default Header;
